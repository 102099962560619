import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import testData from '../MainContent/riskMatrixTestData.json';

// CSS
import styles from '../MainContent/RiskMatrix.module.scss';
// import styles from './ThreeDMatrix.module.scss';

const categories = [
  { name: 'AI', subcategories: ['LLM', 'Image', 'Video', 'Voice'] },
  { name: 'Ethereum', subcategories: ['ZK', 'NFTs'] },
  { name: 'Quantum Computers', subcategories: ['Quantum Cryptography', 'Quantum Simulation'] },
  { name: 'Biotechnology', subcategories: ['Gene Editing', 'Synthetic Biology'] },
  { name: 'BCI', subcategories: ['Neural Interfaces', 'Brain-Machine Interfaces'] },
  { name: 'Robotics', subcategories: ['Autonomous Robots', 'Humanoid Robots'] },
  { name: 'Renewable Energy', subcategories: ['Solar Power', 'Wind Power'] },
  { name: 'Nuclear Weapons', subcategories: ['Nuclear Proliferation', 'Arms Control'] },
  { name: 'Government', subcategories: ['Domestic', 'International'] },
];

class TechGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedCell: '',
      existingComments: [],
      valence: 'opportunity',
      comment: '',
      intensity: 5,
      heatmap: this.loadCommentsFromTestData(),
      activeCategoryX: null,
      activeCategoryY: null,
      activeSubcategoryX: null,
      activeSubcategoryY: null,
    };
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  handleCategoryXClick = (categoryX) => {
    const { activeCategoryX, activeCategoryY, activeSubcategoryX, activeSubcategoryY } = this.state;
    if (activeCategoryX === categoryX && activeSubcategoryX === null) {
      this.setState({ activeCategoryX: null, activeSubcategoryX: null });
    } else if (activeCategoryY !== null && activeSubcategoryY !== null) {
      this.setState({ activeCategoryX: categoryX });
    } else {
      this.setState({ activeCategoryX: categoryX, activeSubcategoryX: null });
    }
  };

  handleCategoryYClick = (categoryY) => {
    const { activeCategoryX, activeCategoryY, activeSubcategoryX, activeSubcategoryY } = this.state;
    if (activeCategoryY === categoryY && activeSubcategoryY === null) {
      this.setState({ activeCategoryY: null, activeSubcategoryY: null });
    } else if (activeCategoryX !== null && activeSubcategoryX !== null) {
      this.setState({ activeCategoryY: categoryY });
    } else {
      this.setState({ activeCategoryY: categoryY, activeSubcategoryY: null });
    }
  };

  handleSubcategoryXClick = (subcategoryX) => {
    const { activeCategoryX, activeSubcategoryX } = this.state;
    if (activeSubcategoryX === subcategoryX) {
      this.setState({ activeSubcategoryX: null });
    } else {
      this.setState({ activeSubcategoryX: subcategoryX });
    }
  };

  handleSubcategoryYClick = (subcategoryY) => {
    const { activeCategoryY, activeSubcategoryY } = this.state;
    if (activeSubcategoryY === subcategoryY) {
      this.setState({ activeSubcategoryY: null });
    } else {
      this.setState({ activeSubcategoryY: subcategoryY });
    }
  };

  handleCellClick = (rowIndex, colIndex) => {
    const { activeCategoryX, activeCategoryY, activeSubcategoryX, activeSubcategoryY } = this.state;
    const subcategoriesX = categories.find((category) => category.name === activeCategoryX)?.subcategories || [];
    const subcategoriesY = categories.find((category) => category.name === activeCategoryY)?.subcategories || [];

    if (
      rowIndex < subcategoriesY.length &&
      colIndex < subcategoriesX.length &&
      activeSubcategoryX !== null &&
      activeSubcategoryY !== null
    ) {
      const subcategoryX = subcategoriesX[colIndex];
      const subcategoryY = subcategoriesY[rowIndex];
      const selectedCell = `${activeCategoryX}.${subcategoryX}.${activeCategoryY}.${subcategoryY}`;
      const existingComments = this.getCommentsForCell(selectedCell);
      this.setState({ selectedCell, existingComments, modal: true });
    }
  };

  getCommentsForCell = (cell) => {
    return testData.filter((commentData) => commentData.cell === cell);
  };

  handleValenceChange = (event) => {
    this.setState({ valence: event.target.value });
  };

  handleCommentChange = (event) => {
    this.setState({ comment: event.target.value });
  };

  handleIntensityChange = (event) => {
    this.setState({ intensity: event.target.value });
  };

  saveCommentToSmartContract = (comment, valence, intensity) => {
    // Placeholder function for writing to the smart contract
    console.log('Saving comment:', comment, 'Valence:', valence, 'Intensity:', intensity);
  };

  loadCommentsFromTestData = () => {
    const heatmap = Array.from({ length: categories.length }, () => Array(categories.length).fill(0));

    testData.forEach((commentData) => {
      const cellParts = commentData.cell.split('.');
      const [rowCategoryX, rowSubcategoryX, rowCategoryY, rowSubcategoryY] = cellParts;
      const rowIndex = categories.findIndex((category) => category.name === rowCategoryY);
      const colIndex = categories.findIndex((category) => category.name === rowCategoryX);

      if (rowIndex !== -1 && colIndex !== -1) {
        const valence = commentData.valence === 'risk' ? -1 : 1;
        heatmap[rowIndex][colIndex] = valence * commentData.intensity;
      }
    });

    return heatmap;
  };

  getColorByValence = (rowIndex, colIndex) => {
    const valence = this.state.heatmap[rowIndex][colIndex];
    if (valence === 0) {
      return '#fff';
    }

    if (valence > 0) {
      return `rgba(0, 255, 0, ${Math.min(valence / 10, 1)})`; // green
    } else {
      return `rgba(255, 0, 0, ${Math.min(Math.abs(valence) / 10, 1)})`; // red
    }
  };

  renderGrid = () => {
    const { activeCategoryX, activeCategoryY, activeSubcategoryX, activeSubcategoryY } = this.state;
    const subcategoriesX = categories.find((category) => category.name === activeCategoryX)?.subcategories || [];
    const subcategoriesY = categories.find((category) => category.name === activeCategoryY)?.subcategories || [];

    if (!activeCategoryX || !activeCategoryY) {
      return null;
    }

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${subcategoriesX.length + 1}, 1fr)`,
          gridTemplateRows: `repeat(${subcategoriesY.length + 1}, 50px)`,
          gap: '1px',
          backgroundColor: '#ccc',
        }}
      >
        <div style={{ gridColumn: 1, gridRow: 1 }} />
        {subcategoriesX.map((subcategoryX, colIndex) => (
          <React.Fragment key={`col-${colIndex}`}>
            <div
              onClick={() => this.handleSubcategoryXClick(subcategoryX)}
              style={{
                gridColumn: colIndex + 2,
                gridRow: 1,
                backgroundColor:
                  activeSubcategoryX === subcategoryX ? '#ddd' : activeCategoryX === null ? '#fff' : '#eee',
                textAlign: 'center',
                color: 'black',
              }}
            >
              {subcategoryX}
            </div>
            {subcategoriesY.map((subcategoryY, rowIndex) => (
              <React.Fragment key={`row-${rowIndex}`}>
                {colIndex === 0 ? (
                  <div
                    onClick={() => this.handleSubcategoryYClick(subcategoryY)}
                    style={{
                      gridColumn: 1,
                      gridRow: rowIndex + 2,
                      backgroundColor:
                        activeSubcategoryY === subcategoryY ? '#ddd' : activeCategoryY === null ? '#fff' : '#eee',
                      textAlign: 'center',
                      color: 'black',
                    }}
                  >
                    {subcategoryY}
                  </div>
                ) : (
                  <div
                    onClick={() => this.handleCellClick(rowIndex, colIndex - 1)}
                    style={{
                      gridColumn: colIndex + 1,
                      gridRow: rowIndex + 2,
                      backgroundColor: this.getColorByValence(rowIndex, colIndex - 1),
                      textAlign: 'center',
                      border: '1px solid #ccc',
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
    );
  };

  render() {
    const { activeCategoryX, activeCategoryY, activeSubcategoryX, activeSubcategoryY } = this.state;

    return (
      <Container>
        <Row>
          <Col>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${categories.length + 1}, 1fr)`,
                gridTemplateRows: `repeat(${categories.length + 1}, 50px)`,
                gap: '1px',
                backgroundColor: '#ccc',
              }}
            >
              <div style={{ gridColumn: 1, gridRow: 1 }} />
              {categories.map((category, rowIndex) => (
                <React.Fragment key={`row-${rowIndex}`}>
                  <div
                    onClick={() => this.handleCategoryYClick(category.name)}
                    style={{
                      gridColumn: rowIndex + 2,
                      gridRow: 1,
                      backgroundColor:
                        activeCategoryY === category.name ? '#ddd' : activeSubcategoryY === null ? '#fff' : '#eee',
                      textAlign: 'center',
                      color: 'black',
                    }}
                  >
                    <Button
                      color="link"
                      onClick={() => this.handleCategoryXClick(category.name)}
                      style={{ padding: 0 }}
                    >
                      {category.name}
                    </Button>
                  </div>
                  <div
                    onClick={() => this.handleCategoryXClick(category.name)}
                    style={{
                      gridColumn: 1,
                      gridRow: rowIndex + 2,
                      backgroundColor:
                        activeCategoryX === category.name ? '#ddd' : activeSubcategoryX === null ? '#fff' : '#eee',
                      textAlign: 'center',
                      color: 'black',
                    }}
                  >
                    <Button
                      color="link"
                      onClick={() => this.handleCategoryYClick(category.name)}
                      style={{ padding: 0 }}
                    >
                      {category.name}
                    </Button>
                  </div>
                  {categories.map((category2, colIndex) => (
                    <React.Fragment key={`col-${colIndex}`}>
                      {rowIndex !== colIndex ? (
                        <div
                          onClick={() => this.handleCellClick(rowIndex, colIndex)}
                          style={{
                            gridColumn: colIndex + 2,
                            gridRow: rowIndex + 2,
                            backgroundColor: this.getColorByValence(rowIndex, colIndex),
                            textAlign: 'center',
                            border: '1px solid #ccc',
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            gridColumn: colIndex + 2,
                            gridRow: rowIndex + 2,
                            backgroundColor: '#eee',
                            textAlign: 'center',
                            border: '1px solid #ccc',
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </Col>
        </Row>

        {activeCategoryX && activeCategoryY && (
          <Row>
            <Col>
              <h4>Selected Categories:</h4>
              <p>X: {activeCategoryX}</p>
              <p>Y: {activeCategoryY}</p>
              <h4>Subcategories for {activeCategoryX}</h4>
              <div style={{ display: 'flex', gap: '10px' }}>
                {categories
                  .find((category) => category.name === activeCategoryX)
                  ?.subcategories.map((subcategory) => (
                    <Button
                      key={subcategory}
                      onClick={() => this.handleSubcategoryXClick(subcategory)}
                      color={activeSubcategoryX === subcategory ? 'primary' : 'secondary'}
                    >
                      {subcategory}
                    </Button>
                  ))}
              </div>
              <h4>Subcategories for {activeCategoryY}</h4>
              <div style={{ display: 'flex', gap: '10px' }}>
                {categories
                  .find((category) => category.name === activeCategoryY)
                  ?.subcategories.map((subcategory) => (
                    <Button
                      key={subcategory}
                      onClick={() => this.handleSubcategoryYClick(subcategory)}
                      color={activeSubcategoryY === subcategory ? 'primary' : 'secondary'}
                    >
                      {subcategory}
                    </Button>
                  ))}
              </div>
              {this.renderGrid()}
            </Col>
          </Row>
        )}

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={styles.riskMatrixCommentModal}>
          <ModalHeader toggle={this.toggle} style={{ backgroundColor: '#fff', color: 'black' }}>
            Add Comment for Cell {this.state.selectedCell}
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#fff', color: 'black' }}>
            {this.state.existingComments.length > 0 && (
              <>
                <h5 style={{ backgroundColor: '#fff', color: 'black' }}>Existing comments:</h5>
                <ul>
                  {this.state.existingComments.map((comment, index) => (
                    <li key={index} style={{ backgroundColor: '#fff', color: 'black' }}>
                      {comment.comment} - {comment.valence} (Intensity: {comment.intensity})
                    </li>
                  ))}
                </ul>
                <hr />
              </>
            )}
            <h5 style={{ backgroundColor: '#fff', color: 'black' }}>Add a new comment:</h5>
            <Input
              type="textarea"
              placeholder="Enter your comment here..."
              value={this.state.comment}
              onChange={this.handleCommentChange}
            />
            <FormGroup tag="fieldset" style={{ backgroundColor: 'blue', color: 'black', fontWeight: '600' }}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="valence"
                    value="opportunity"
                    checked={this.state.valence === 'opportunity'}
                    onChange={this.handleValenceChange}
                  />
                  Opportunity
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="valence"
                    value="risk"
                    checked={this.state.valence === 'risk'}
                    onChange={this.handleValenceChange}
                  />
                  Risk
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label for="intensity">Intensity: {this.state.intensity}</Label>
              <Input
                type="range"
                name="intensity"
                min="1"
                max="10"
                value={this.state.intensity}
                onChange={this.handleIntensityChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ backgroundColor: '#fff', borderTop: '1px solid #dee2e6' }}>
            <Button
              color="primary"
              onClick={() => {
                this.saveCommentToSmartContract(
                  this.state.comment,
                  this.state.valence,
                  this.state.intensity
                );
                this.toggle();
              }}
            >
              Save Comment
            </Button>{' '}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default TechGrid;