import React, { Component } from "react";
// import Torus from '@toruslabs/torus-embed';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeAccount } from '../../actions/accountActions.js';
import { toggleLoginModal, updateLoginInfo } from '../../actions/sessionStateActions.js';
import { ConnectButton } from '@rainbow-me/rainbowkit';

// Footer-HooksHOC is a function component (allowed to use Hooks from wagmi and RainbowKit) 
// it passes props to this class-component so that this component can use React Hooks
import { WagmiHooksHOC } from '../HooksHOC/WagmiHooksHOC.jsx'

// CSS & Icons & 
import '../../assets/css/m_w.css'
import styles from "./Login.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: use library 
import { faWindowClose, faCoins, faSpinner, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'
// import { faTwitch, faGoogle, faRedditAlien, faDiscord, faFacebook, faEthereum } from '@fortawesome/free-brands-svg-icons'
// Logos
import MetaMaskLogo from "assets/img/metamask_icon_white.png";
import RainbowLogo from "assets/img/rainbow_icon.png";
import CoinbaseLogo from "assets/img/coinbase_icon.png";
import WalletConnectLogo from "assets/img/walletconnect_icon.png";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Form, Modal, UncontrolledTooltip} from "reactstrap";

// Components
import TorusLoginButton from "./TorusLoginButton.jsx";
// import TorusLoginButton from "./Web3AuthLoginButton.jsx"
// import TorusLoginButton from "./FunctionalWeb3AuthConnect";
import RPCFaucetNotice from "./RPCFaucetNotice.jsx";
// import FunctionComponent from "./FunctionComponent.jsx";
import SendTestnetFundsButton from "../Buttons/SendTestnetFundsButton.jsx";
import DemoModeSelect from "./DemoModeSelect.jsx";
import UserPage from "components/UserPage/UserPage.jsx";

// Smart Contract Interactions
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts.js';

class Web3Modal extends Component {
    state = {
       // When a wagmi / RainbowKit login or account-switch is detected, this gets turned to true 
       // in order to run the function updateStateUponWagmiLogin()
        wagmiLoginUpdateNeeded: true, 
        // TODO: after connecting with a web3 provider, 
        // a dialogue box appears with relevant information
        afterLoginDialogue: true,
        firstModalAfterLogin: false, // only true for the first modal-view after login
        copied: false,
    };

    componentDidMount() {}

    componentDidUpdate(prevProps) {    
        console.log("Web3Modal.jsx - Updated");
        // console.log("this.props.address: " + this.props.address);
        // console.log("this.props.account: " + this.props.account);

        // INTENDED TO DETECT WHEN LOGIN FROM RAINBOWKIT/WAGMI IS SUCCESSFUL
        // TODO: modify for disconnect?
        // this.props.network – m_w redux global state
        //  this.props.wagmiNetwork – wagmi-injected React Context value from HOC component
        if (this.props.network != this.props.wagmiNetwork) {
            console.log("this.props.network != this.props.wagmiNetwork")
            console.log("this.props.network")
            console.log(this.props.network)
            console.log("this.props.wagmiNetwork")
            console.log(this.props.wagmiNetwork)
            // console.log("this.props.network: "+ this.props.network)
            // console.log("this.props.wagmiNetwork: ")
            // console.log(this.props.wagmiNetwork)

            // this.setState({ wagmiLoginUpdateNeeded: true });

            // this.setState({ wagmiLoginUpdateNeeded: true });   
            // this.state.wagmiLoginUpdateNeeded === false
        }

        // TODO: this.props.address is undefined here, change to this.props.account?
        else if (this.props.account != this.props.wagmiAddress ) {
            console.log("this.props.account != this.props.wagmiAddress")
            console.log("this.props.account: "+ this.props.account)
            console.log("this.props.wagmiAddress: "+ this.props.wagmiAddress)
        
            this.updateStateUponWagmiLogin();

            this.setState({wagmiLoginUpdateNeeded: true})
        }

        // Detect new login, so that relevant information can show up for user
        if (this.props.loginComplete && this.props.loginComplete != prevProps.loginComplete) {
            this.setState({firstModalAfterLogin: true})
        }

        // TODO: update on network change in wagmi / rainbowkit by checking last props to see if network is different
        // if (this.props.network != prevProps.network) {
        //     console.log("this.props.network != prevProps.network")
        //     console.log("this.props.network: "+ this.props.network)
        //     console.log("prevProps.network: "+ prevProps.network)
        //     this.setState({ wagmiLoginUpdateNeeded: true });
        // }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(nextProps);
        // console.log(nextState);
    
        // UPDATE IF LOGIN IN PROGRESS OR ENDING
        const loginStageDifferent = nextProps.loginInProgress != this.props.loginInProgress;
        if (loginStageDifferent) {
          return true;
        }
    
        // INTENDED TO FORCE UPDATE IF MODAL TOGGLED FROM ANOTHER COMPONENT
        if (nextProps.loginModalToggled != this.props.loginModalToggled ) {
        //   console.log("Global Modal Toggle Detected")
        //   console.log("Next loginModalToggled:" + nextProps.loginModalToggled)
        //   console.log("Current loginModalToggled:" + this.props.loginModalToggled)
          return true;
        }

        // TODO: maybe move this to ComponentDidUpdate and check condition --> call function
        if (this.props.network != this.props.wagmiNetwork) {
            // console.log("nextProps.network != this.props.wagmiNetwork")
            return true
        }

        return true;
    }

    openLoginModal = () => { 
        console.log("Web3Panel: Opened")
        // this.setState({modalOpen: true})
        document.body.classList.add('modal-open');
    
        const open = true;
        this.props.toggleLoginModal(open);
    }

    // TODO: same name-change as above
    closeLoginModal = () => {
        // console.log("Web3Panel: Closed")
        // this.setState({modalOpen: false})
        document.body.classList.remove('modal-open');

        const closed = false;
        this.props.toggleLoginModal(closed);

        // get rid of any one-time notices / messages
        if (this.state.firstModalAfterLogin === true) {
            this.setState({firstModalAfterLogin: false})
        }
    };

    updateStateUponWagmiLogin = async () => {
        if (this.state.wagmiLoginUpdateNeeded === true) {
            this.setState({wagmiLoginUpdateNeeded: false})
            console.log("updateStateUponWagmiLogin() – connected address is: " + this.props.wagmiAddress)

            var loginObject = {
                loginInProgress: true,
                loginComplete: false, // only if this is called 
                provider: "wagmi",
            };

            this.props.updateLoginInfo(loginObject);

            // TODO: set provider (accessible via)
            // window.web3 = this.props.wagmiProvider

            let connectedAddress = this.props.wagmiAddress
            console.log("connectedAddress:")
            console.log(connectedAddress)

            let availableETH = this.props.wagmiBalance;
            let ETHBalance =  this.props.demoMode ? contractScripts.toEighteenDecimals("0") : await contractScripts.getETHBalance(connectedAddress);
            let XPBalance = this.props.demoMode ? contractScripts.toEighteenDecimals("100") : await contractScripts.getXPBalance(connectedAddress);
            // console.log("XPBalance: " + XPBalance.toString())
            let networkName = this.props.network.name
            // console.log("this.props.wagmiNetwork: ")
            // console.log(this.props.wagmiNetwork)

             // TODO: use better way of detecting whether player has joined
            var hasJoined = (XPBalance.toString() == "0") ? false : true;
            console.log("hasJoined: " + hasJoined)

            // Update Account State (when balance info available)
            const web3info = {
                account: connectedAddress, 
                provider: "wagmi", // TODO?
                network: networkName,
                joined: hasJoined,
                availableETH: availableETH,
                XPBalance: XPBalance, 
                ETHBalance: ETHBalance
            };

            // TODO: call to SERVER to grant free sidechain / testnet ETH so that user can interact with dApp
            // socket.on("FromAPI", data => this.setState({ numPlayersOnline: data }));
            // socket.emit('ethAddressLogin', metamaskAddress); // TODO: Use 'ack" parameter?
            // socket.send(metamaskAddress); // TODO: Use 'ack" parameter?

            this.props.changeAccount(web3info);

            // update sessionState variables
            loginObject = {
                loginInProgress: false,
                loginComplete: true, 
            };

            this.props.updateLoginInfo(loginObject);
        }

        else { 
            // console.log("else-block: YOU SHOULDN'T SEE THIS")
        }

    }

    updateStateTorusLogin = () => {
    }


    getModalDisplay = () => {
        // console.log("getModalDisplay() invoked");

        // check if there is any special login-message to display
        var newLoginMessage = "";
        if (this.state.firstModalAfterLogin && this.state.afterLoginDialogue) {
            if (this.props.provider == "Torus") {
                newLoginMessage = "Torus Crypto Wallet: Connected"
            }
        }

        // if LOGIN INCOMPLETE
        if (!this.props.loginComplete) {
                return (
                    <CardBody>
                        <div id={styles.modalLoginOptions}>
                            <div id={styles.metamaskInstructions}>
                            <Button
                                onClick={this.props.openConnectModal}
                                id={styles.modalMetamaskButton}
                                color="orange"
                                type="button"
                            >
                                <img
                                    src={MetaMaskLogo}
                                    id={styles.metamaskModalIcon}
                                    // id={metamaskIconID}
                                    // height="60px"
                                    // width="60px"
                                    alt="Metamask Logo"
                                />

                                <img
                                    src={RainbowLogo}
                                    id={styles.metamaskModalIcon}
                                    // id={metamaskIconID}
                                    // height="60px"
                                    // width="60px"
                                    alt="Rainbow Logo"
                                />

                                <img
                                    src={CoinbaseLogo}
                                    id={styles.metamaskModalIcon}
                                    // id={metamaskIconID}
                                    // height="60px"
                                    // width="60px"
                                    alt="Coinbase Logo"
                                />

                                <img
                                    src={WalletConnectLogo}
                                    id={styles.metamaskModalIcon}
                                    // id={metamaskIconID}
                                    // height="60px"
                                    // width="60px"
                                    alt="WalletConnect Logo"
                                />

                                <h1 id={styles.metamaskConnectText}> 
                                {/* <h1 id={metamaskConnectTextID}>  */}
                                {/* CONNECT  */}
                                </h1>
                            </Button>
                            </div>
                       

                            <div id={styles.torusLoginOption}>          
                                <TorusLoginButton loginInProgress={this.props.loginInProgress} demoMode={this.props.demoMode} />
                            </div>
                        </div>
                   </CardBody>
                );
        }

        // if LOGIN IN PROGRESS
        if (this.props.loginInProgress) {
        <div id={styles.loadingIconContainer}> 
            <h3 id={styles.verifyingtext}> logging in... </h3> 
            <FontAwesomeIcon icon={faSpinner} pulse id={styles.verifyingTXloadingIcon} /> 
          </div>
        }

        // if LOGIN COMPLETE + provider is "wagmi"
        else if (this.props.loginComplete && this.props.provider == "wagmi") {
            return (
                <CardBody id={styles.metamaskModalPanel}>
                    <ConnectButton accountStatus={{
                        smallScreen: 'avatar',
                        largeScreen: 'full',
                        }}
                        id={styles.metamaskModalPanel}
                    />

        <UserPage 
            viewAddress={this.props.account} 
            // address={this.props.address} 
            provider={this.props.provider}
            minimized={true}
            network={this.props.network}
          />

                </CardBody>

                
            );
        }

        // if LOGIN COMPLETE + provider is "Torus"
        else if (this.props.loginComplete && this.props.provider == "Torus") {

            console.log()

            const addressDisplay = this.props.account != undefined ?
            proposalScripts.getShortenedAddress(this.props.account)
            :
            <FontAwesomeIcon icon={faSpinner} pulse id={styles.verifyingTXloadingIcon} />
            ;

            return (
                <CardBody id={styles.torusModalCard}>
                    <div id={styles.torusModal}>

                        
                        {/* <div id={styles.torusInfoModalTitle}> { newLoginMessage } </div> */}
                        
                        <div id={styles.torusModalBody}>

                        <h1 id={styles.userModalAddress}>

                        <UserPage 
            viewAddress={this.props.account} 
            // address={this.props.address} 
            provider={this.props.provider}
            minimized={true}
          />
          
                        </h1>
                            
                        {/* TORUS WALLET BUTTON / WIDGET SHOWS UP IN BOTTOM LEFT ONLY WHEN MODAL IS OPEN */}
                        </div>
                    </div>
                </CardBody>
            );
        }
    }

    getConnectedNetworkDisplay = () => {
    }

    getSettingsDisplay = () => {

        var sendTestnetFundsButton = this.props.loginComplete ? // TODO: Make this based on connectivity to blockchain network AND server (to send test funds)
        <SendTestnetFundsButton 
          homepage={false} 
          changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          focusedTab={this.props.focusedTab}
          lobby={this.props.lobby}
          account={this.props.account}
          paid={this.props.paid}
          provider={this.props.provider} 
          loginComplete={this.props.loginComplete}
          text="Get Test Funds"
          //
          sendTestETH={(amountToSend) => this.props.sendTestETH(amountToSend)} 
        />
        :
        <></>;

        const networkName = this.props.network == undefined ? "not connected" : this.props.network.name;
        console.log("networkName: " + networkName)
        console.log("this.props.network.name: ") 
        console.log(this.props.network.name)

        var networkAndDemoSection = 
        <>  
            <div id={styles.settingsShortText}>
                <p> network: <div id="numberVotersValue"> { networkName } </div> </p>
                {/* <p> community: <div id="numberVotersValue"> trial #1 </div> </p> */}
            </div>

            { sendTestnetFundsButton }

            {/* <RPCFaucetNotice /> */}

                                    <DemoModeSelect 
                                     textMode={false} // renders as button
                                     toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
                                     demoMode={this.props.demoMode}
                                    />
                                    </>

        return networkAndDemoSection;

    }

    getModalTitle = () => {
        if (!this.props.loginComplete) {
            return "LOGIN";
        }

        else if (this.props.provider == "wagmi") {
            return "WALLET";
        }

        else if (this.props.provider == "Torus") {
            const torusWalletNotice = (
                <h3 id={styles.loginNotice}>
                Click on "T" (bottom left) to use 
                {/* Torus wallet */}
                </h3>
            )

            const torusWalletRow = (
                <div id={styles.torusWalletRow}>
                   { "WALLET"} {" "} { torusWalletNotice }
                </div>
            )

            return torusWalletRow;
        }
    }

    render() {

        const modalTitle = this.getModalTitle();
        const modalContent = this.getModalDisplay();
        const modalFooter = this.getSettingsDisplay();

        return (
            <div id={styles.loginModal}>
                <Modal isOpen={this.props.loginModalToggled} modalClassName="modal-login">  
                    <Card id={styles.loginModalCard}>
                        <CardHeader>
                            <div className={styles.Web3SettingsModalTitle}> 
                                { modalTitle } 
                            </div>

                            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.closeLoginModal}>
                                <FontAwesomeIcon id={styles.closeModalIcon} icon={faWindowClose} />
                            </button>
                        </CardHeader>

                        { modalContent }

                        <div className={styles.Web3SettingsModalSubtitle}> 
                            SETTINGS
                        </div>

                        <CardFooter className="text-center" id={styles.settingsFooter}> 

                        { modalFooter }

                        </CardFooter>
                     </Card>
                 </Modal>
          </div>
        )
    }
}

Web3Modal.propTypes = {
  loginModalToggled: PropTypes.bool,
  loginInProgress: PropTypes.bool,
  loginComplete: PropTypes.bool,
  // loginWeb3Provider: PropTypes.string, // TODO: get rid of when modal is fixed
  provider: PropTypes.string,
  changeAccount: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  updateLoginInfo: PropTypes.func.isRequired,  
};
  
const mapStateToProps = state => ({
  // profile state
  provider: state.profile.provider,
  network: state.profile.network, 
//   address: state.profile.address, 
  account: state.profile.account,
  // session state
  loginModalToggled: state.sessionState.loginModalToggled,
  loginInProgress: state.sessionState.loginInProgress,
  loginComplete: state.sessionState.loginComplete,
  // loginWeb3Provider: state.sessionState.loginWeb3Provider,
  });
  
  const LoginModalWithWagmiHooks = WagmiHooksHOC(Web3Modal)
  
  export default connect(mapStateToProps, { changeAccount, toggleLoginModal, updateLoginInfo })(LoginModalWithWagmiHooks);


// Old Metamask Login Option Button:

//   <Button
//   onClick={this.updateStateUponWagmiLogin}
// //   id={metamaskButtonID}
// id={styles.modalMetamaskButton}
//   color="orange"
//   type="button"
// >
//   <img
//     src={MetaMaskLogo}
//     id={styles.metamaskModalIcon}
//     // id={metamaskIconID}
//     // height="60px"
//     // width="60px"
//     alt="Metamask Logo"
//   />

// <h1 id={styles.metamaskConnectText}> 
// {/* <h1 id={metamaskConnectTextID}>  */}
//   CONNECT 
// </h1>
// </Button>