import React, { useState } from 'react';
import { Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import styles from './RightSide.module.scss';
// Images
import adImage1 from '../../assets/img/butterfly.png';
import adImage2 from '../../assets/img/ladyOutline.png';
import adImage3 from '../../assets/img/fight.png';

const AdSpotWithImages = ({ demoMode }) => {
  const [showAd, setShowAd] = useState(false);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  if (!demoMode) {
    return null;
  }

  return (
    <div id={styles.adSpotWithImages}>
      <Card className={styles.rightSideCard}>
        <CardBody id={styles.adSpotWithImagesCardBody}>
          {!showAd ? (
            <h3 id={styles.adSpotWithImagesText} onClick={() => setShowAd(true)}>
              {/* ZK AD */}
            </h3>
          ) : (
            <>
              <div className={styles.chatHeader}>
                <button className={styles.closeButton} onClick={() => setShowAd(false)}>
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <img src={adImage1} alt="Ad 1" className={styles.adImage} />
            </>
          )}
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal} id={styles.adModal}>
        <ModalHeader toggle={toggleModal}>
          <h3 id={styles.adModalTitle}>Vote for Your Favorite Ad</h3>
        </ModalHeader>
        <ModalBody>
          <img src={adImage2} alt="Ad 2" className={styles.modalImage} onClick={() => alert('Voted for Ad 1')} />
          <img src={adImage3} alt="Ad 3" className={styles.modalImage} onClick={() => alert('Voted for Ad 2')} />
          <p>
            All early advertisers receive incentives and winning advertisers will receive x% refund. Ad revenue goes
            to community in which ad is interacted with for redistribution among contributors to novel data supplied by
            a given community. High-revenue subcommunities will subsidize more niche ones via quadratic mechanism.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdSpotWithImages;