import React, { Component } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import styles from "./DenverPage.module.scss";

// Components
// import ForArtists from "./ForArtists.jsx"
// import ForDevs from "./ForDevs.jsx"
import ForUsers from "./ForUsers.jsx"
// import ForAdvertisers from "./ForAdvertisers"
// import ForInvestors from "./ForInvestors.jsx"


class DenverPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div className={styles.tabsContainer}>
        <Nav tabs className={styles.tabsNav}>
          <NavItem className={styles.navItem}>
          <NavLink id={styles.navLink}    
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
            >
              Users
            </NavLink>
          </NavItem>
          <NavItem className={styles.navItem}>
          <NavLink id={styles.navLink}              
          className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
            >
              Artists
            </NavLink>
          </NavItem>
          <NavItem className={styles.navItem}>
          <NavLink id={styles.navLink}    
             className={classnames({ active: this.state.activeTab === "3" })}
              onClick={() => {
                this.toggle("3");
              }}
            >
              Devs
            </NavLink>
          </NavItem>
          <NavItem className={styles.navItem}>
          <NavLink id={styles.navLink}    
              className={classnames({ active: this.state.activeTab === "4" })}
              onClick={() => {
                this.toggle("4");
              }}
            >
              Solarpunk / RxC
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className={styles.tabsContent}>
          <TabPane tabId="1">
            <p>{ this.props.nftCode }</p>
          </TabPane>
          <TabPane tabId="2">
            <p>Tab 2 content</p>
          </TabPane>
          <TabPane tabId="3">
            <p>Tab 3 content</p>
          </TabPane>
          <TabPane tabId="4">
            <p>Tab 4 content</p>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default DenverPage;
