import React, { Component } from 'react';
import classNames from 'classnames';
import { Card, CardBody, CardFooter } from 'reactstrap';
import styles from './RightSide.module.scss'; // Replace 'YourStyles' with the actual SCSS file name
import PlayersOnlineChart from './PlayersOnlineChart';

class PlayersOnline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPlayers: 97,
      animationClass: '',
      firstUpdate: true, // To track the first update
      dataPoints: Array.from({ length: 10 }, () => 97 + Math.random() * 10),
    };
  }

  componentDidMount() {
    if (this.props.demoMode) {
      this.interval = setInterval(this.updateNumPlayers, 5000); // 5 seconds
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.demoMode && !this.props.demoMode) {
      // TODO: uncomment below line when player-counter server is running
      // this.setState({ numPlayers: this.props.numPlayers || 0 });
      clearInterval(this.interval);
      this.setState({ numPlayers: 0 });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateNumPlayers = () => {
    const action = Math.random(); // Get a random number between 0 and 1
    let delta;
    if (action < 0.8) {
      delta = Math.floor(Math.random() * 6); // Random number between 0 and 5
    } else {
      delta = Math.floor(Math.random() * -6); // Random number between -5 and 0
    }
    const newNumPlayers = Math.max(this.state.numPlayers + delta, 0);
    this.setState(prevState => ({
      numPlayers: newNumPlayers,
      dataPoints: [...prevState.dataPoints.slice(1), newNumPlayers],
      animationClass: prevState.firstUpdate ? 'numPlayersGlowsUpdateFirst' : '',
    }));
    if (this.state.firstUpdate) {
      this.setState({ firstUpdate: false });
    }
    setTimeout(() => {
      this.setState({ animationClass: '' });
    }, 5000);
  };

  render() {
    const { numPlayers, animationClass, dataPoints } = this.state;
    const digits = numPlayers.toString().split('');
    return (
      <div id={styles.playersOnline}>
        <Card className={styles.rightSideCard}>
          <CardBody className={styles.playersOnlineCardBody}>
            <h3 id={styles.playersOnlineNumber} className={classNames(animationClass)}>
              {numPlayers}
              <div id={styles.playersOnlineText}>online</div>
            </h3>
            <PlayersOnlineChart dataPoints={dataPoints} />
          </CardBody>
          <CardFooter>{/* Existing CardFooter content */}</CardFooter>
        </Card>
      </div>
    );
  }
}

export default PlayersOnline;