import React, { useState, useEffect } from "react";
import styles from "./RightSide.module.scss";
import { Card, CardBody, CardFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCheck, faArrowRight, faTimes, faSpinner, faSmile } from "@fortawesome/free-solid-svg-icons";

const sampleChatsOther = [
  { id: 1, type: "received", text: "Would you tell me, please, which way I ought to go from here?", timestamp: '2023-09-12' },
  { id: 2, type: "sent", text: "That depends a good deal on where you want to get to.", timestamp: '2023-09-12' },
  { id: 3, type: "received", text: "I don't much care where—", timestamp: '2023-09-12' },
];

const sampleChatsAI = [
  { id: 1, type: "received", text: "What do you think about computers being conscious?", timestamp: '2023-09-12' },
];

const ChatBox = ({ demoMode }) => {
  const [chats, setChats] = useState(sampleChatsOther);
  const [input, setInput] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [continueTyping, setContinueTyping] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [chatMode, setChatMode] = useState("ai");
  const [interests, setInterests] = useState("policy");
  const [chatWith, setChatWith] = useState("local");
  const [showGlowMessage, setShowGlowMessage] = useState(false);
  const [glowMessageIcon, setGlowMessageIcon] = useState(faSpinner);

  useEffect(() => {
    setChats(chatMode === "other" ? sampleChatsOther : sampleChatsAI);
  }, [chatMode]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSend = () => {
    setShowGlowMessage(true);
    setTimeout(() => {
      setGlowMessageIcon(faSmile);
    }, 2500);
    setTimeout(() => {
      setShowGlowMessage(false);
    }, 5000);

    setChats([...chats, { id: chats.length + 1, type: "sent", text: input, timestamp: new Date().toISOString().split('T')[0] }]);
    
    const replyText = chatMode === "ai" ? "Interesting!" : "Just so long as I learn something";
    setChats(prevChats => [...prevChats, {
      id: prevChats.length + 1,
      type: "received",
      text: replyText,
      timestamp: new Date().toISOString().split('T')[0]
    }]);

    setInput("");
  };

  useEffect(() => {
    if (showChat && continueTyping) {
      const timer = setInterval(() => {
        setChats(prevChats => [...prevChats, {
          id: prevChats.length + 1,
          type: "received",
          text: "...",
          timestamp: new Date().toISOString().split('T')[0]
        }]);
      }, 5000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [showChat, chatMode, interests]);

  const renderTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    return (
      <span className={styles.glowingTimestamp} title={time}>
        {time}
      </span>
    );
  };

  if (!demoMode) {
    return null;
  }

  return (
    <div id={styles.adSpot}>
      <Card className={styles.rightSideCard}>
        <CardBody id={styles.adSpotCardBody}>
          {!showChat && !showSettings ? (
            <div className={styles.panelSelect}>
              <h3 id={styles.adspotMessage} onClick={() => setShowChat(true)}>CHAT</h3>
              <FontAwesomeIcon id={styles.chatSettingsIcon} icon={faCog} onClick={() => setShowSettings(true)} />
            </div>
          ) : null}

          {showSettings && (
            <>
              <button className={styles.closeButton} onClick={() => setShowSettings(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <h3 id={styles.chatSettingsLabel}> 
              {/* Chat Settings */}
              </h3>
              <div id={styles.chatSettingsContainer}>
                <div className={styles.flexRow}>
                  <label id={styles.chatSettingsText}>Chat With:</label>
                  <input type="radio" value="other" checked={chatMode === "other"} onChange={() => setChatMode("other")} />Other Users
                  <input type="radio" value="ai" checked={chatMode === "ai"} onChange={() => setChatMode("ai")} />AI Interviewer
                </div>
                <div className={styles.flexColumn}>
                  <label id={styles.chatSettingsText}>Chat Interests:</label>
                  <input type="text" value={interests} onChange={(e) => setInterests(e.target.value)} />
                  <label id={styles.chatSettingsText}>Chat Options:</label>
                  <select value={chatWith} onChange={(e) => setChatWith(e.target.value)}>
                    <option value="local">Current Lobby </option>
                    <option value="spectators">Non-member spectators</option>
                    <option value="interests">Anyone with similar interests</option>
                  </select>
                </div>
                <a className={styles.goToChat} onClick={() => setShowChat(true)}>
                  Go To Chat <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </div>
            </>
          )}

          {showChat && (
            <>
              <button className={styles.closeButton} onClick={() => setShowChat(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <div className={styles.chatArea}>
                {chats.map((chat) => (
                  <div key={chat.id} className={`${styles.chatBubble} ${styles[chat.type]}`}>
                    {chat.text} {renderTimestamp(chat.timestamp)}
                  </div>
                ))}
              {showGlowMessage && (
              <div className={styles.glowingMessageCenter}>
                Checking for novelty or connections <FontAwesomeIcon className={styles.spinningIcon} icon={glowMessageIcon} />
              </div>
            )}
              </div>
              <div className={styles.inputArea}>
                <input type="text" value={input} onChange={handleInputChange} />
                <button onClick={handleSend}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </>
          )}

        </CardBody>
        <CardFooter>
          {/* your footer content */}
        </CardFooter>
      </Card>
    </div>
  );
};

export default ChatBox;