import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faExclamationCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import contractScripts from './contractScripts.js';
import styles from './SendTestnetFundsButton.module.scss';

class SendTestnetFundsButton extends Component {
  state = {
    loading: false,
    success: false,
    error: false,
    transactionHash: null,
    errorMessage: ""
  };

  sendTestnetFunds = async () => {
    this.setState({ loading: true, success: false, error: false, transactionHash: null, errorMessage: "" });
    try {
      const receipt = await contractScripts.sendTestnetFunds(
        this.props.provider,
        this.props.account
      );
      this.setState({ loading: false, success: true, transactionHash: receipt.transactionHash });
      console.log("Transaction successful!");
    } catch (error) {
      const errorMessage = error.message.includes("threshold") ? "Wallet already has balance" : error.message;
      this.setState({ loading: false, error: true, errorMessage });
      console.error("Transaction failed:", error);
    }
  }

  getExplorerLink = (hash) => {
    const network = this.props.network; // Assuming network prop contains network details
    return network ? `${network.blockExplorers.default.url}/tx/${hash}` : 'https://sepolia.basescan.org/tx/' + hash;
  }

  render() {
    const { loading, success, error, transactionHash, errorMessage } = this.state;
    const buttonText = loading ? "Processing..." :
      success ? "Test Funds Sent" :
      error ? `Error Sending: ${errorMessage}` :
      "Send Test Funds";
    const relevantID = this.props.homepage ? styles.loadingScreenInitButton : styles.startMatchButton;

    return (
      <Button 
        onClick={this.sendTestnetFunds} 
        id={relevantID} 
        className={`${styles.button} ${loading ? styles.loading : ''} ${success ? styles.success : ''} ${error ? styles.error : ''}`} 
        disabled={loading}
      > 
        { loading ? <FontAwesomeIcon icon={faSpinner} spin className={styles.loadingIcon} /> : buttonText }
        { success && <FontAwesomeIcon icon={faCheckCircle} className={styles.successIcon} /> }
        { success && <a href={this.getExplorerLink(transactionHash)} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faExternalLinkAlt} className={styles.externalLinkIcon} />
        </a> }
        { error && <FontAwesomeIcon icon={faExclamationCircle} className={styles.errorIcon} /> }
      </Button>
    );
  }
}

export default SendTestnetFundsButton;
