// import React, { Component } from "react";
// import "./PublicTrello.scss";

// class PublicTrello extends Component {
//   render() {
//     return (
//       <div className="public-trello-container">
//         <iframe
//           title="public-trello-board"
//           src="https://trello.com/b/HXfYqteI.html"
//           frameBorder="0"
//           className="public-trello-board"
//         />
//       </div>
//     );
//   }
// }

// export default PublicTrello;


import React from "react";
import styles from "./PublicTrello.module.scss";

const PublicTrello = () => {
  return (
    <div className={styles.publicTrello}>
      <iframe src="https://trello.com/b/HXfYqteI.html" />
    </div>
  );
};

export default PublicTrello;