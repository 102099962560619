import React, { Component } from "react";
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAccount, updateXPBalance, updateETHBalance } from '../actions/accountActions.js';
import { updateCurrMatch, updateMatches, updateProposals } from '../actions/lobbyActions.js';
import { fetchSessionState, changeFocusedTab, toggleLoginModal, updateLoginInfo, changeFocusedMatchID, toggleDemoMode  } from '../actions/sessionStateActions.js';

// CSS
import "assets/css/m_w.css";
import styles from "./Main.module.scss";

// DemoMode Data
import web2DemoData from '../variables/web2DemoVotes.js';
import web2DemoProposals from '../variables/web2DemoProposals.js';

// Smart Contract Events / Interactions
import contractScripts from './Buttons/contractScripts.js';
import { ethers } from 'ethers';
import { SEND_TEST_ETH } from '../variables/CONTRACT_ADDRESSES.js'; // true or false
// Footer-HooksHOC is a function component (allowed to use Hooks from wagmi and RainbowKit) 
// it passes props to this class-component so that this component can use React Hooks
import { WagmiHooksHOC } from './HooksHOC/WagmiHooksHOC.jsx'

// Components
// import GreetingModal from "components/InformationModals/GreetingModal.jsx";
// Main Content:
import DenverPage from "./DenverPage/DenverPage.jsx";
import Navbar from "./Navbar/Navbar.jsx";
import MainAreaTabs from "./MainContent/MainAreaTabs.jsx";
import MainAreaTabsAlt from "./MainContent/MainAreaTabsAlt.jsx"; // To make early demo less overwhelming
import Footer from "./Footer/Footer.jsx";
import Rightside from "./RightSidebar/Rightside.jsx";
import RightsideAlt from "./RightSidebar/RightSideAlt.jsx"; // To make early demo less overwhelming
// Contributors Area:
import ContributorsArea from "./ContributorsArea/ContributorsArea.jsx";
// About Page:
import AboutPage from "./AboutPage/AboutPage.jsx"
// User Page
import UserPage from "./UserPage/UserPage.jsx";
import SimulatedUserPage from "./UserPage/SimUserPage.jsx";
// Risk Matrix Demo
import RiskMatrixDemo from "./DemoPages/RiskMatrixDemo.jsx";
// 3D Risk Matrix Demo
import ThreeDMatrix from "./DemoPages/ThreeDMatrix.jsx";
// Ideas Map Table
import IdeaMap from "./IdeaMap/IdeaMap.jsx";
// Contract Information / Display Page
import ContractPage from "./ContractPage/ContractPage.jsx";
import CompareAddresses from "./UserPage/CompareAddresses.jsx";
// Survey Tool
import SurveyPage from "./SurveyTool/SurveyPage.jsx";
// SBT Tools
import SBTPage from "./SBTs/SBTPage.jsx";
import SBTsPage from "./SBTs/SBTsList.jsx"; // TODO: replace with SBTsPage

// NOTE: GLOBAL STATE managed with redux
class MemeWarsSite extends Component {
  state = {
    // rulesModalOpen: false,
    // didDeepDiff: false, // TODO: for comparing .js objects TODO (deepDiff.js)

    listeningForEvents: false, // web3 listening (contract updates) is initialized when this.props !== undefined

    loadedLatestMatch: false,
    loadingLatestMatch: false,

    loadedFocusedMatch: false,
    loadingFocusedMatch: false,

    loadedAllMatches: false, 
    loadingAllMatches: false,

    loadedProposals: false,
    loadingProposals: false,

    // when new match starts, change focused match to latest
    autoSnapToNewMatch: true,  // TODO: should be a setting for user
    // TODO: having an issue, when below is true: can't switch to another match, app glitches
    searchForCachedMatches: true, // TODO: should be a setting in another file
    latestMatchNumber: null,  // Updated / Used in: extendMatchesArrayForNewMatch() // NOTE: equivalent to latestMatchID
    // BLOCKCHAIN-RELATED
    sentTestETH: false,       // TODO: true on sign-in if person has no ETH
    latestBlockNumber: null,  // Used in: listenForMatchUpdates() and listenForProposalUpdates()
    // Demo functionality (for showing idea before users exist)
    demoModeOverrideToFalse: false,  // Setting can be toggled by user in Web3 / Settings modal, turns off fetching of onchain data and replaces with example data for web2 demo 
  }

// COMPONENT LIFECYCLE START *************************************************************
  
  componentDidMount() {

    // get global settings for website from redux store
    this.props.fetchSessionState();

    // TODO: check if there is any embedded code in the urlExtension
    console.log("this.props.urlExtension:" + this.props.urlExtension)

    if (this.state.demoModeOverrideToFalse) {
      this.props.toggleDemoMode(false)
    }

    if (this.props.web2DemoMode) {
      console.log("web2DemoMode - skipping smart-contract interactions and WS setup")
      this.loadDemoMatches();
      this.getLobbyProposals(); // Demo switch inside
    }

    // if in web2DemoMode: avoid all smart-contract interactions and WS setup 
    else if (!this.props.web2DemoMode) {

      // establish Web3 connection (WSS) to be used wherever possible (contractScripts.js, lower components, etc.)
      window.provider = this.props.wagmiProvider
      window.wsProvider = this.props.wagmiWsProvider;
      console.log("window.provider:")
      console.log(window.provider)
      console.log("window.wsProvider:")
      console.log(window.wsProvider)


      this.updateLatestBlockNumber();
      this.startEventListener();
      // this.loadLatestMatchNumber(); NOTE: called in the function-call below
      this.loadMatchesFromLocalStorage();
    
      // TODO: should this be somewhere else? test - setting state in componentDidMount() ? (was working before, can move back into "listen for updates" func or somewhere else... maybe just a function called from DidMount)
      // Below fires if web3 connection fails / disconnects --> restarts web3 connection
      // NOTE - `listeningForEvents` used to re-establish connection 
      // window.provider.on('disconnect', (error) => {
      //     console.log('Web3 (WSS) Connection - DISCONNECTED:');
      //     console.log("IF THIS FIRES AFTER ACCOUNT DISCONNECT, WRONG TYPE OF 'disconect', trying to do it if WSS or HTTP provider fails, but this is probably handled internally by wagmi")
      //     console.log(error);
      //     // if web3 connection fails, need to indicate need for re-boot
      //     this.setState({
      //         listeningForEvents: false, 
      //     });
      // });

    }

    // this.setState({ rulesModalOpen: true });
    // if(this.props.firstVisit) {
    //   this.setState({ rulesModalOpen: true });
    // }
  }

  // disconnect established connections before shut-down
  // - socket.io connection: established in App.jsx
  componentWillUnmount() {
    if (this.props.socket !== undefined) {
    //   // SERVERTODO  
    //   this.props.socket.disconnect() // server connection (established in App.jsx)
    }

    // disconnect Torus web3 connection if relevant
    if (this.props.provider == "Torus") {
      window.torus.cleanup()
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("MemewarsSite.jsx - UPDATED")

    console.log("this.props.matches:")
    console.log(this.props.matches)
    console.log("this.props.currMatch")
    console.log(this.props.currMatch)
    console.log("this.props.focusedMatchID:")
    console.log(this.props.focusedMatchID)

    // ALL-SITE
    const userNeedsTestETH = this.props.loginComplete && !this.state.sentTestETH && SEND_TEST_ETH;

    // LATEST MATCHES TAB
    const needCurrMatchReload = !this.state.loadedLatestMatch && !this.state.loadingLatestMatch && this.props.currMatch !== undefined;
    const needAllMatchesReload = !this.state.loadedAllMatches && !this.state.loadingAllMatches;

    // FUTURE MATCHES TAB
    const needProposalsReload = !this.state.loadedProposals && !this.state.loadingProposals;

    if (!this.props.web2DemoMode) {
      // load latest match (if not loaded) or refresh (if need for refresh indicated)
      if (needCurrMatchReload) {
        // console.log("needCurrMatchReload - CALLING loadLatestMatch()");
        this.loadLatestMatch();
      }
    
      // load rest of matches (upon start, in lazy way TODO) or refresh / add-to `loadedMatches` (if need indicated)
      if (needAllMatchesReload) {
        // console.log("needAllMatchesReload - CALLING loadAllMatches()");
        this.loadAllMatches();
      }

      // load match proposals relevant to lobby (for FUTURE MATCHES)
      if (needProposalsReload) {
        // console.log("needProposalsReload - CALLING getLobbyProposals()");
        this.getLobbyProposals();
      }

      // send logged-in user test-funds / sidechain ETH (for ETH gas fee --> site interactions)
      if (userNeedsTestETH) {
        this.getUserTestETH();
        // TODO: check for success of above ^ before setting this to true
        this.setState({ sentTestETH: true })
      }
    }
  }

// COMPONENT LIFECYCLE END ***************************************************************



// SERVER COMMUNICATION START TODO -------------------------------------------------------

  sendMessageToServer = () => {
    this.props.socket.send("MESSAGE from MemeWarsSite.jsx");        // TODO: Use 'ack" parameter?
    // const hardCodedAddress = "0xFFcf8FDEE72ac11b5c542428B35EEF5769C409f0"
    // this.props.socket.emit('ethAddressLogin', hardCodedAddress); // TODO: Use 'ack" parameter?
    // this.props.socket.send(hardCodedAddress);                    // TODO: Use 'ack" parameter?
  }

  getUserTestETH = (amountToSend) => { // optional parameter
    // TODO: only send if visitor has a 0-balance (so that nobody can drain)

    // console.log("sending testnet ETH to:")
    // console.log(this.props.account)
    // console.log("TODO: TURN testnet coins server BACK ON")
    // console.log("amountToSend:")
    // console.log(amountToSend)

    // console.log("this.props.socket:")
    // console.log(this.props.socket)

    // TODO: Use 'ack" parameter?     
    // TODO: Use amountToSend parameter? 
    // this.props.socket.emit('ethAddressLogin', this.props.account.toString());     
  }

// SERVER COMMUNICATION END  -------------------------------------------------------------



// LATEST MATCH TAB START ****************************************************************

  // loads demo matches data if this.props.web2DemoMode is true
  loadDemoMatches = async () => {
    console.log("loadDemoMatches() - invoked");

    if (this.props.web2DemoMode) {

      this.setState({ latestMatchNumber: web2DemoData.length - 1})

      const web2DemoDataFixedBN = await this.traverseBignumberFix(web2DemoData)
      const web2DemoDataFixedBNAndTimestamps = this.updateDemoMatchTimestamps(web2DemoDataFixedBN)

      this.props.updateMatches(web2DemoDataFixedBNAndTimestamps);

      // this.setState({ 
      //   // loadedLatestMatch: true,         
      //   // loadingLatestMatch: false,
      //   // does not change latestMatchNumber unless this call to loadLatestMatch() is directly after new event has been spotted
      //   // latestMatchNumber: mostRecentLatestMatchNumber 
      // })

      // const latestMatchObj = this.props.matches[this.state.latestMatchNumber - 1];
      // console.log("demoMode: web2DemoData:")
      // console.log(web2DemoData)
      // console.log("demoMode: this.props.matches:")
      // console.log(this.props.matches)
      const latestMatchObj = web2DemoDataFixedBNAndTimestamps[web2DemoData.length - 1];
     
      // console.log("demoMode: latestMatchObj:")
      // console.log(latestMatchObj)
      this.props.updateCurrMatch(latestMatchObj);


      this.props.changeFocusedMatchID(web2DemoData.length)
    }
  }

  updateDemoMatchTimestamps = (dataArray) => {
    return dataArray.map(data => {
      if (data.matchStatus === 'ACTIVE') {
        // Get current Unix timestamp
        const currentTimestamp = moment().unix();
        
        // Update the votingStartTime to be 60 seconds before the current time
        data.votingStartTime = currentTimestamp - 30;
      }

      else if (data.matchStatus === 'PENDING') {
        // Get current Unix timestamp
        const currentTimestamp = moment().unix();
        
        // Update the matchStartTime to be 60 seconds after the current time
        data.matchStartTime = currentTimestamp + 60;
      }
  
      return data;
    });
  }

  // updates this.props.currMatch
  loadLatestMatch = async () => {
    // console.log("loadLatestMatch() - invoked");

    // retrieve match if it's not already being loaded + needs to be loaded
    if (!this.state.loadingLatestMatch && !this.state.loadedLatestMatch) {
      // console.log("loadLatestMatch() - #1 - LOADING LATEST MATCH");

      // getLatestMatchByLobby() returns 0 if there are no matches in a given lobby
      if (this.state.latestMatchNumber != null && this.state.latestMatchNumber > 0) {
        // console.log("loadLatestMatch() - #1 #1 - Latest Match # is: " + this.state.latestMatchNumber);
        
        // blocks reset loops (this line  will only be reached once per reload)
        this.setState({ loadingLatestMatch: true })

        // var mostRecentLatestMatchNumber = this.state.latestMatchNumber;
        let latestMatchObj = await contractScripts.getMatchInfoByID(this.state.latestMatchNumber);  // #NORELOAD
        // console.log("latestMatchObj:");
        // console.log(latestMatchObj);

        this.setState({ 
          loadedLatestMatch: true,         
          loadingLatestMatch: false,
          // does not change latestMatchNumber unless this call to loadLatestMatch() is directly after new event has been spotted
          // latestMatchNumber: mostRecentLatestMatchNumber 
         })

        this.props.updateCurrMatch(latestMatchObj);

        // update local cache's most-recent entry as well
        // if there have been any changes
        const lastRecordedMatch = this.props.matches[this.state.latestMatchNumber - 1]
        console.log("lastRecordedMatch")
        console.log(lastRecordedMatch)
        console.log("latestMatchObj")
        console.log(latestMatchObj)

        if (JSON.stringify(lastRecordedMatch) !== JSON.stringify(latestMatchObj)) {
        this.updateCurrMatchInLocalStorage(latestMatchObj)
        }
      }

      else { 
        console.log("loadLatestMatch - ELSE-BLOCK #1 #1 - this.state.latestMatchNumber == null");
      }
    }

    else { /* console.log("loadLatestMatch - ELSE-BLOCK #1") */ }
  }

  loadMatchByID = async (matchToLoadID) => {
      console.log("loadMatchByID() - invoked");

      if (this.state.latestMatchNumber >= matchToLoadID) {
        console.log("loadMatchByID() - #1 - LOADING (from smart-contracts) Match #: " + matchToLoadID);

        let requestedMatchObj = await contractScripts.getMatchInfoByID(matchToLoadID);
        // console.log("focusedMatchObj:");
        // console.log(focusedMatchObj);

        // make a copy of this.props.matches - by value, not by reference (see https://www.samanthaming.com/tidbits/35-es6-way-to-clone-an-array/
        const matchesArrayCopy = [...this.props.matches];

        // insert (or update) this newly loaded match into matches array
        matchesArrayCopy[matchToLoadID] = requestedMatchObj;

        // update global state of matches to include this newly-loaded match
        this.props.updateMatches(matchesArrayCopy);
        this.saveMatchesToLocalStorage(matchesArrayCopy);

        return requestedMatchObj;
      }

      else { console.log("loadMatchByID - ELSE-BLOCK - !(latestMatchNumber >= matchToLoadID)") }
  }

  loadLatestMatchNumber = async () => {
    console.log(" loadLatestMatchNumber() - invoked")
    let latestMatchNumber = await contractScripts.getLatestMatchByLobby(this.props.lobby, this.props.paid);
    console.log("latestMatchNumber:")
    console.log(latestMatchNumber);
    if (latestMatchNumber > 0) {
        this.setState({ latestMatchNumber: latestMatchNumber })
    }
  }

  // Fetch history of all matches 
  // - TODO: not effecient, load on demand
  loadAllMatches = async () => {
    console.log("loadAllMatches - invoked")
    let numLobbyMatches = await contractScripts.getNumberOfMatchesByLobby(this.props.lobby, this.props.paid);
    console.log("numLobbyMatches")
    console.log(numLobbyMatches)
    if (numLobbyMatches > 0 && !this.state.loadedAllMatches && !this.state.loadingAllMatches) {
      // console.log("loadAllMatches - #1")
      this.setState({ loadingAllMatches: true })
      let allMatchesObj = await contractScripts.getMatchesByLobby(this.props.lobby, this.props.paid);

      // console.log("allMatchesObj is");
      // console.log(allMatchesObj);

      // if (this.props.currMatch != null && allMatchesObj != this.props.matches && allMatchesObj !== undefined && allMatchesObj != null) {
      if (allMatchesObj.length != 0) {
        // console.log("loadAllMatches - #1 #1")
        console.log("ALL MATCHES UPDATED")
        this.setState({ loadedAllMatches: true } );
        this.props.updateMatches(allMatchesObj);
        this.saveMatchesToLocalStorage(allMatchesObj)
        console.log("allmatchesObj:")
        console.log(allMatchesObj)
      }
    }
  }

  // save matches array to local storage
  // TODO: MULTILOBBYTODO
  saveMatchesToLocalStorage = (matchesObj) => {
    console.log("saveMatchesToLocalStorage() - invoked");

    // convert array to string
    const matchesArrayString = JSON.stringify(matchesObj);

    // set key-value in localStorage
    localStorage.setItem('matchesCache', matchesArrayString);
  }

  // load matches array from local storage
  // TODO: MULTILOBBYTODO
  loadMatchesFromLocalStorage = async () => {
    console.log("loadMatchesFromLocalStorage() - invoked");
    this.setState({ loadingAllMatches: true }); // blocks this.loadAllMatches() from being called before cache is searched
    const retrievedMatchesArrayString = localStorage.getItem('matchesCache');
    const matchesCache = JSON.parse(retrievedMatchesArrayString);

    if (this.state.latestMatchNumber == null) { await this.loadLatestMatchNumber() }

    console.log("matchesCache:");
    console.log(matchesCache)
    console.log("this.state.latestMatchNumber:")
    console.log(this.state.latestMatchNumber)

    if (matchesCache != null && matchesCache.length <= this.state.latestMatchNumber && this.state.searchForCachedMatches) {
      console.log("loadMatchesFromLocalStorage() - #1 - matchesCache != null");
      // check if latest match in matchesCache is the same as latest match in this component (this.state.latestMatchNumber)
      const latestMatchInCache = matchesCache[matchesCache.length - 1];
      console.log("latestMatchInCache:");
      console.log(latestMatchInCache);
      if (latestMatchInCache.ID == this.state.latestMatchNumber) {
        console.log("loadMatchesFromLocalStorage() - #1 #1 - latestMatchInCache.matchID == this.state.latestMatchNumber");
        // populates this.props.currMatch
        this.loadLatestMatch()
        this.setState({ loadedAllMatches: true, loadingAllMatches: false }); // TODO: should this be above?
        // if so, simply update global state:
        const matchesCacheWithProperBignumbers = await this.traverseBignumberFix(matchesCache);
        this.props.updateMatches(matchesCacheWithProperBignumbers);

      } else {
        // else, load missing matches from this.loadMissingMatches()
        this.loadMissingMatches(matchesCache);
      }
    }
    else { 
      this.setState({ loadingAllMatches: false }); // allows this.loadAllMatches() to work if cache empty
      this.loadAllMatches(); 
    }
  }

  // NOTE: might be able to trim this down (or instead of searching recurseively, just know from contractScripts
  // which fields are BigNumbers and just convert those).
  // There is some weirdness with ethers.js custom implementation of BigNumber
  // and they are not "right" (compatible with ethers.js functions) immediately after JSON.parse()
  // so we have to convert them back to ethers.js BigNumbers
  // TODO: move this to contractScripts.js ?
  traverseBignumberFix = async (data) => {
    // console.log("traverse() - invoked");
    // console.log("data:", data)
    if (Array.isArray(data)) {
      data.forEach(item => this.traverseBignumberFix(item));
    } else if (typeof data === 'object') {
      Object.entries(data).forEach(([key, value]) => {
        if (value && value.type === 'BigNumber') {
          // console.log("data[key]: (is a BigNumber)")
          // console.log(data[key])
          data[key] = contractScripts.getBigNumber(value, "MemewarsSite.jsx");
        }
        this.traverseBignumberFix(value);
      });
    }

    // console.log("traverseBignumberFix() - returned data:")
    // console.log(data)
    return data;
  }

  // load missing matches (after call to loadMatchesFromLocalStorage())
  // to fully populate matches array, then save to local storage 
  // TODO: test this by using two different browsers and seeing if the one catches up 
  // and calls loadMissingMatches() 
  loadMissingMatches = async (matchesCache) => {
    console.log("loadMissingMatches() - invoked");
    const latestMatchID = this.state.latestMatchNumber;
    console.log("latestMatchID:")
    console.log(latestMatchID)
    console.log("this.state.latestMatchNumber: ")
    console.log(this.state.latestMatchNumber)

    let matchToLoadID = matchesCache.length;
    while (matchToLoadID <= latestMatchID) {
      let requestedMatchObj = await contractScripts.getMatchInfoByID(matchToLoadID);
      matchesCache.push(requestedMatchObj);
      matchToLoadID++;
    }
    
    this.saveMatchesToLocalStorage(matchesCache);
    this.props.updateMatches(matchesCache);
    this.setState({ loadedAllMatches: true, loadingAllMatches: false }); // TODO: should this be above?

  }

  updateCurrMatchInLocalStorage = (matchObj) => {
    // console.log("updateCurrMatchInLocalStorage() - invoked");
    const retrievedMatchesArrayString = localStorage.getItem('matchesCache');
    var matchesCache = JSON.parse(retrievedMatchesArrayString);
    matchesCache[matchObj.ID - 1] = matchObj;
    this.saveMatchesToLocalStorage(matchesCache);
    // console.log("matchesCache after updateCurrMatchInLocalStorage():")
    // console.log(matchesCache)
  }

  // returns relevant match object based on (this.props.focusedMatchID)
  //
  //  - if relevant match already exists in global match state (this.props.matches), return copy
  //  - else, if match has not been loaded, load it (from smart contracts) and add to global state
  //
  // TODO: shouldn't be async, because matches should be loaded beforehand (ideally: lazy loading)
  getFocusedMatchObject = () => {
    // TODO: check state object (whether match already populated)
    // if not, call loadMatchByID
    // if so, do the below procedure (find index, maybe +-1)
    // TODO: make sure this makes sense with lobbies
    // PROBLEM: match-id is not per-lobby currently (MULTILOBBYTODO)
    
    // TODO: handle dealing with making matches array larger if new match detected
    const matches = this.props.matches;
    // console.log("matches in getFocusedMatchObject():")
    // console.log(matches)
    const focusedMatchID = this.props.focusedMatchID;
    // console.log("focusedMatchID in getFocusedMatchObject():")
    // console.log(focusedMatchID)

    // NOTE: matches[] array IS 0-indexed, while match numbers are NOT 0-indexed
    if (matches[focusedMatchID - 1] != null) { 
      console.log("matches[focusedMatchID - 1]:")
      console.log(matches[focusedMatchID - 1])
      return matches[focusedMatchID - 1] }

    // TODO: is this block ever hit, in practice?
    else {
      console.log("getFocusedMatchObject() - focusedMatchID not found in matches[] array - loading from smart contracts")
      this.setState({ loadingFocusedMatch: true });

      const focusedMatchObj = this.loadMatchByID(focusedMatchID);

      this.setState({ 
        loadedFocusedMatch: true,
        loadingFocusedMatch: false 
       });

      return focusedMatchObj;
    } 
  }

  getLatestBalances = async () => {
    console.log("getLatestBalances() - invoked - UPDATING (XP / ETH) BALANCES")
    var latestXPBalance = null;
    var latestETHBalance = null;

    latestXPBalance = await contractScripts.getXPBalance(this.props.account.toString());
    latestETHBalance = await contractScripts.getETHBalance(this.props.account.toString());

    // console.log("latestXPBalance:")
    // // console.log(latestXPBalance) // BigNumber, doesn't print nicely
    // console.log(latestXPBalance.toString())

    if (latestXPBalance != null && latestXPBalance != this.props.XPBalance) {
      // console.log("getLatestBalances() - UPDATING XP BALANCE")
      this.props.updateXPBalance(latestXPBalance);
    }

    if (this.props.paid && latestETHBalance !== undefined && latestETHBalance != this.props.ETHBalance) {
      // console.log("getLatestBalances() - UPDATING ETH BALANCE")
      this.props.updateETHBalance(latestETHBalance);
    }

  }

  // MAKES SPACE FOR NEW MATCH IN `LOADED MATCHES` ARRAY
  // AND ADDS THE NEW MATCH AS WELL
  extendMatchesArrayForNewMatch = async () => {
    // console.log("extendMatchesArrayForNewMatch() - invoked");

    // // this.state.latestMatchNumber is incremented in this.onNewEventDetected(), in the NewMatch case
    // // so the below line has the effect of adding one (+1) to the length of the array
    // // NOTE: this.props.matches IS 0-indexed (array indices DON'T line up with MATCH ID)
    // const adjustedArrayLength = this.state.latestMatchNumber;     

    // make a copy of this.props.matches - by value, not by reference 
    // see https://www.samanthaming.com/tidbits/35-es6-way-to-clone-an-array/
    const matchesArrayCopy = [...this.props.matches];

    console.log("matchesArrayCopy:")
    console.log(matchesArrayCopy)

    // get latest match by ID
    // TODO HERE
    const latestMatchObj = await contractScripts.getMatchInfoByID(this.state.latestMatchNumber)
    console.log("latestMatchObj:")
    console.log(latestMatchObj)

    const matchesArrayCopyWithLatestMatchAdded = matchesArrayCopy.concat(latestMatchObj);
    // const matchesArrayCopyWithLatestMatchAdded = matchesArrayCopy.push(latestMatchObj);

    // const matchesArrayCopyWithEmptySlot = matchesArrayCopy.push({});

    console.log("matchesArrayCopyWithLatestMatchAdded: ")
    console.log(matchesArrayCopyWithLatestMatchAdded)

    // TODO: necessary to update global state at this stage,
    // or could it only be updated when the match info is actually
    // available at the end of the array?
    this.props.updateMatches(matchesArrayCopyWithLatestMatchAdded);
    // this.saveMatchesToLocalStorage()
  }


// LATEST MATCH TAB END ******************************************************************

// SMART-CONTRACT EVENT HANDLERS START ***************************************************
// Events followed / detected in contractScripts listenForMatchUpdates() and WagmiHooksHOC.jsx

  startEventListener = async () => {
    contractScripts.listenForNewEvents(this.onNewEventDetected)
    this.setState({ listeningForEvents: true })

    // TODO: monitor websockets listener for disconnect (AFTERTRUFFLE) and set this.setState( { listeningForEvents: false }) if disconnect detected
  }

  // fired (as callback function) from event-listener in WagmiHooksHOC.jsx
  onNewEventDetected = async (event) => {
    console.log("onNewEventDetected() – invoked")
    console.log("event: ")
    console.log(event)
    // NOTE: the event.event field seems to be glitching (maybe Truffle / MM?) 
    // but the event.eventSignature seems reliable (AFTERTRUFFLE)

    const newEvent = event.blockNumber > this.state.latestBlockNumber
    console.log("newEvent:")
    console.log(newEvent)
    console.log("event.blockNumber:")
    console.log(event.blockNumber)
    console.log("this.state.latestBlockNumber:")
    console.log(this.state.latestBlockNumber)

    // ------------------------------ Matches Events ------------------------------
    // TODO: MULTILOBBYTODO (only notify for relevant lobbies)

    // NEW MATCH DETECTED
    if (newEvent && event.eventSignature == "NewMatch(uint256,bytes32,bool)") {
      this.onNewMatchDetected(event);
    }

    // MATCH INFO UPDATE
    else if (newEvent && event.eventSignature == "MatchInfoUpdate(uint256,bytes32,bool)") { 
      this.onMatchInfoUpdateDetected(event);
    }

    // MATCH CANCELLED
    else if (newEvent && event.eventSignature == "MatchCancelled(uint256,bytes32,bool)") {
      this.onMatchCancelledDetected(event);
    }

    // ----------------------------- Proposals Events -----------------------------
    // TODO: MULTILOBBYTODO (only notify for relevant lobbies)


    // NEW PROPOSAL SUBMITTED
    if (newEvent && event.eventSignature == "NewProposalSubmitted(bytes32,bytes32,bool,address)") {
      this.onNewProposalSubmittedDetected(event);
    }

    else if (newEvent && event.eventSignature == "ProposalVoteChange(bytes32,int256)") {
      this.onProposalVoteChangeDetected(event);
    }

    else if (newEvent && event.eventSignature == "ProposalChosen(bytes32,bytes32,bool)") {
      this.onProposalChosenDetected(event);
    }

    else if (event.eventSignature == "DefaultProposalUpdated(bytes32,bytes32,bool)") {}

    else if (event.eventSignature == "NewLobbyCreated(string,bytes32)") {}
  }
  
  // Matches Events
  
  onNewMatchDetected = async (event) => {  
    const newMatchID = event.args[0].toNumber();
    var currMatchID = this.props.currMatch != null ? this.props.currMatch.ID : 0;
    // console.log("NEW MATCH SPOTTED - ID #: " + newMatchID); 
    
    if (newMatchID > currMatchID) {
        console.log("setting this.state.loadedLatestMatch = FALSE")

        // this.loadLatestMatchNumber()

        // update this.state.latestMatchNumber
        // NOTE: latestMatchNumber is 0-indexed
        this.setState({ latestMatchNumber: newMatchID })

        // adds +1 slot (onto array of all matches) with NEW MATCH
        // and updates cached matches array
        await this.extendMatchesArrayForNewMatch(); 
  
        // indicate need for reload of component state, so that loadLatestMatch() works
        this.setState({ loadedLatestMatch: false, loadingLatestMatch: false })

        // updates this.props.currMatch
        await this.loadLatestMatch();

        if (this.state.autoSnapToNewMatch) { this.props.changeFocusedMatchID(newMatchID) }
  
        // TODO: CACHEMATCHES
        // reloadAllMatchFunc();
        // loadPastLobbyMatchesFunc();
    }
  }

  onMatchInfoUpdateDetected = async (event) => {
    console.log("onMatchInfoUpdateDetected() – invoked")

    // NOTE: loadLatestMatch won't work otherwise
    this.setState({ loadedLatestMatch: false, loadingLatestMatch: false })
    await this.loadLatestMatch();                             

    // TODO: in theory these are doing the exact same thing,
    // should be removed when move to a model of loading one match at a time
    // reloadAllMatchFunc();
    // loadPastLobbyMatchesFunc();

    // await this.getLatestBalances(); // TODO: needed?
  }

  onMatchCancelledDetected = async (event) => {
    console.log("onMatchCancelledDetected() – invoked")
    
    // NOTE: loadLatestMatch won't work otherwise
    this.setState({ loadedLatestMatch: false, loadingLatestMatch: false })
    await this.loadLatestMatch();          

    // // TODO: in theory these are doing the exact same thing,
    // // should be removed when move to a model of loading one match at a time
    // // CACHEMATCHES
    // reloadAllMatchFunc();
    // loadPastLobbyMatchesFunc();

 // TODO: causes error after reloading site if matchCancelled event is last to fire. Picked up by contractScripts.js even though the block number is lower than the threshold meant to be needed to trigger the event. (AFTERTRUFFLE TODO: check if this is still an issue)

    if (this.props.loginComplete && this.props.currMatch != null) { await this.getLatestBalances(); }
  }

  // Proposals Events

  onNewProposalSubmittedDetected = async (event) => {
    console.log("onNewProposalSubmittedDetected() – invoked")
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();   
  }

  onProposalVoteChangeDetected = async (event) => {
    console.log("onProposalVoteChangeDetected() – invoked")
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();
  }

  onProposalChosenDetected = async (event) => {
    console.log("onProposalChosenDetected() – invoked")
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();
  }



// SMART-CONTRACT EVENT HANDLERS END ***************************************************



// FUTURE MATCHES / PROPOSALS START ------------------------------------------------------------

  getLobbyProposals = async () => {
    // console.log("getLobbyProposals() - invoked");

    // load demo proposals if web2DemoMode enabled
    if (this.props.web2DemoMode) {
      console.log("getLobbyProposals() - loading demo proposals:");
      console.log(web2DemoProposals);
      this.props.updateProposals(web2DemoProposals);
      this.setState({ loadedProposals: true })
    }

    else if (!this.state.loadingProposals && !this.state.loadedProposals) {
      // console.log("getLobbyProposals() - fetching proposals");
      this.setState({ loadingProposals: true })
      let lobbyProposals = await contractScripts.getProposalsByLobby(this.props.lobby, this.props.paid);

      // getProposalsByLobby() returns [] if smart-contract call unsuccessful
      const emptyProposalsResponse = [];
      if (lobbyProposals != emptyProposalsResponse) {
        this.props.updateProposals(lobbyProposals);  // #NORELOAD
        this.setState({ loadedProposals: true })
      }

      this.setState({ loadingProposals: false });

      }
    }

// FUTURE MATCHES / PROPOSALS END --------------------------------------------------------------



// MODALS START ------------------------------------------------------------

  toggleModalLogin = () => {
    // this.state.toggleLoginModal(!this.props.loginModalToggled);
    // this.setState({ rulesModalOpen: !this.state.rulesModalOpen });
  };

  closeRulesModal = () => {
    this.setState({ rulesModalOpen: false });
  };

// MODALS END ------------------------------------------------------------

// update latest block # in component state
// TODO: handle error if it occurs (https://wagmi.sh/docs/hooks/useBlockNumber#return-value)
updateLatestBlockNumber = async () => {
    // console.log("this.props.wagmiBlocknumber:")
    // console.log(this.props.wagmiBlocknumber)
    // const lastBlockNumber = this.props.wagmiBlocknumber;
    // NOTE: this should be called pageLoadBlockNumber, not latestBlockNumber
    // because it is only used to block old events
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const lastBlockNumber = await provider.getBlockNumber();
    console.log("lastBlockNumber:")
    console.log(lastBlockNumber)
    this.setState({  latestBlockNumber: lastBlockNumber  }); 
}


// React-Router Paths START ------------------------------------------------------------

  getMainView = (relevantMatch) => {
    // console.log("getMainView() – invoked")

    // MAIN SITE VIEW
    if (this.props.path == "/" || this.props.path == "" ) {
      return ( 
        <div id={styles.main}>
          <MainAreaTabsAlt 
            updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
            changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
            updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            //
            toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
            // ************************* 
            // profile={this.props.profile}
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // ************************* 
            account={this.props.account} 
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance} 
            ETHbalance={this.props.ETHBalance} 
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid} 
            match={relevantMatch} // #CURRLOGIC 
            currMatch={this.props.currMatch} // #CURRLOGIC 
            matches={this.props.matches}
            proposals={this.props.proposals}
            focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
            // DEMO MODE
            demoMode={this.props.web2DemoMode}
            // NETWORK
            network={this.props.network} // this comes through WAGMI HOC
          />

          <RightsideAlt
            numPlayers={this.props.numPlayers} 
            account={this.props.account} 
            // web2DemoMode={this.props.web2DemoMode}
            demoMode={this.props.web2DemoMode}
            // network={this.props.network}
          />

        </div>
      );
    }

    else if (this.props.path == "/beta") {
      return ( 
        <div id={styles.main}>
          <MainAreaTabs 
            updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
            changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
            updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            //
            toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
            // ************************* 
            // profile={this.props.profile}
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // ************************* 
            account={this.props.account} 
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance} 
            ETHbalance={this.props.ETHBalance} 
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid} 
            match={relevantMatch} // #CURRLOGIC 
            currMatch={this.props.currMatch} // #CURRLOGIC 
            matches={this.props.matches}
            proposals={this.props.proposals}
            focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
            // DEMO MODE
            demoMode={this.props.web2DemoMode}
            // NETWORK
            network={this.props.network} // this comes through WAGMI HOC
          />

          <Rightside 
            numPlayers={this.props.numPlayers} 
            account={this.props.account} 
            // web2DemoMode={this.props.web2DemoMode}
            demoMode={this.props.web2DemoMode}
            // network={this.props.network}
          />

        </div>
      );
    }

    else if (this.props.path == "/polis") {
      //
    }

    // check for address comparison before individual address page
    else if (this.props.path.includes("/compare/")) {
      // pull first address out of URL, it starts with 0x and finishes with & 
      // (where the second address begins)
      const firstAddress = this.props.path.slice(4, 46);
      // console.log("firstAddress:")
      // console.log(firstAddress)
      return (
        <CompareAddresses firstAddress={firstAddress}/>
      );
    }

    // check for survey comparison before individual address page
    else if (this.props.path.includes("/surveys") || this.props.path.includes("/survey/")) {
      // pull first address out of URL, it starts with 0x and finishes with & 
      const surveyID = this.props.path.slice(8, 74);
      // Check if there is a second slash after "/survey/" and an Ethereum address
      const pathParts = this.props.path.split('/');
      const displayAnswerMode = pathParts.length > 3 && pathParts[3].startsWith('0x') && pathParts[3].length === 42;

      const viewResponseAddress = displayAnswerMode ? pathParts[3] : null;

      console.log("surveyID:")
      console.log(surveyID)
      console.log("displayAnswerMode:")
      console.log(displayAnswerMode)

      return (
        <SurveyPage
          surveyID={surveyID}
          displayAnswerMode={displayAnswerMode} // Added prop
          viewAddress={viewResponseAddress} // Added prop
          //
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          account={this.props.account} 
          provider={this.props.provider}
          joined={this.props.joined}
          XPBalance={this.props.XPBalance} 
          ETHbalance={this.props.ETHBalance} 
          availableETH={this.props.availableETH}
          lobby={this.props.lobby}
          paid={this.props.paid} 
          loginComplete={this.props.loginComplete}
          loginInProgress={this.props.loginInProgress}
          //
          network={this.props.network}
        /> 
      );
    }

    else if (this.props.path.includes("/sbts")) {
      return (
        <SBTsPage
        provider={this.props.provider}
        account={this.props.account}
        network={this.props.network}
        modalView={true} // only true from CommunityTab.jsx
        //
        loginComplete={this.props.loginComplete}
        toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
        expanded={true}
        />
      );
    }

    // check for SBT page before individual address page
    // the SBT url may be url/sbt/0xAddress/ClaimPassword, 
    // so we store the sbtAddress in a prop called sbtAddress
    // and the password in a prop called sbtPassword
    else if (this.props.path.includes("/sbt/")) {
        const path = this.props.path;
        // Extract the SBT address and optional claim password from the URL
        const pathParts = path.split('/');
        const sbtAddress = pathParts[2];
        const sbtPassword = pathParts.length > 3 ? pathParts[3] : null;
        // console.log("SBT Address:", sbtAddress);
        // console.log("SBT Password:", sbtPassword);
  
        return (
          <SBTPage
            SBTAddress={sbtAddress}
            sbtMintPassword={sbtPassword}
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            account={this.props.account}
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance}
            // ETHBalance={this.props.ETHBalance}
            // availableETH={this.props.availableETH}
            // lobby={this.props.lobby}
            // paid={this.props.paid}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
            //
            network={this.props.network}
            chains={this.props.wagmiChainOptions}
            blockNumber={this.props.wagmiBlocknumber}
          />
        );
    }

    // check for simulated user page, determined by whether 
    // the path includes /su/<simUsername>
    else if (this.props.path.includes("/su/")) {
      const simUsername = this.props.path.slice(4);
      // console.log("simUsername:")
      // console.log(simUsername)
      return (
        <SimulatedUserPage
          simUsername={simUsername}
          provider={this.props.provider}
          network={this.props.network}
        />
      );
    }

    // Check if viewAddress is defined and contains "0x"
    else if (this.props.path.includes("0x")) {
      // to distinguish from normal use of "address" in props, which is the 
      // signed-in address of the user (not necessarily the same as address they are viewing)
      const viewAddress = this.props.path.slice(1).replace("u/", "");
      // console.log("viewAddress:")
      // console.log(viewAddress)
      
      // if provider is undefined, pass in wagmiProvider
      // TODO: sort out provider logic
      // const provider = this.props.provider == undefined ? this.props.wagmiProvider : this.props.provider;

      // console.log("this.props.wagmiProvider")
      // console.log(this.props.wagmiProvider)

      return (
          <UserPage 
            viewAddress={viewAddress} 
            address={this.props.address} 
            provider={this.props.provider}
            network={this.props.network}
          />
      );
    }

    else if (this.props.path == "/contributors") { 
      return (
        <ContributorsArea />
      );
    }

    else if (this.props.path == "/about") {
      return (
        <AboutPage />
      );
    }

    else if (this.props.path == "/matrix") {
      return (
        <RiskMatrixDemo />
      );
    }

    else if (this.props.path == "/3dmatrix") {
      return (
        <ThreeDMatrix />
      );
    }

    else if (this.props.path == "/ideas") {
      return (
        <IdeaMap />
      );
    }

    // TODO: not sure why trailing slash shows up on this link and not others
    else if (this.props.path == "/contracts" || this.props.path == "/contracts/") {
      return (
        <ContractPage />
      );
    }
    
    else if (this.props.path == "/denver") {
      console.log("nftCode:")
      console.log(this.props.nftCode)
      return (
        <DenverPage 
          nftCode={this.props.nftCode} 
        />
      );
    }
  }

  render() {

    const currMatchDefined = this.props.currMatch != null;
    const noMatches = this.props.focusedMatchID == null || this.props.matches.length == 0;
    const currMatchIsFocused = noMatches || currMatchDefined && this.props.focusedMatchID.toString() == this.props.currMatch.ID.toString();
    
    const relevantMatch = currMatchIsFocused ? this.props.currMatch : this.getFocusedMatchObject();
    // (async () => {
    //   await this.getFocusedMatchObject().resolve()
    // })();
    
    console.log("render() – relevantMatch:")
    console.log(relevantMatch)
    console.log("this.props.focusedMatchID:")
    console.log(this.props.focusedMatchID)
    console.log("this.props.loginComplete:" + this.props.loginComplete)

    const mainViewDisplay = this.getMainView(relevantMatch); 

    return (
        <>
          {/* <GreetingModal /> */}
          <Navbar 
              // updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
              // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
              // updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance()) ?
              updateXPBalance={(newXPBalance) => this.newXPBalance(newXPBalance)} 
              toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
              updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
              toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)}
              demoMode={this.props.web2DemoMode}
              // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
              // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
              // ************************* 
              // profile={this.props.profile}
              // lobbyInfo={this.props.lobbyInfo}
              // sessionState={this.props.sessionState}
              // ************************* 
              account={this.props.account} 
              provider={this.props.provider}
              joined={this.props.joined}
              XPBalance={this.props.XPBalance} 
              ETHbalance={this.props.ETHBalance} 
              availableETH={this.props.availableETH}
              lobby={this.props.lobby}
              paid={this.props.paid} 
              // currMatch={this.props.currMatch}
              // matches={this.props.matches}
              // proposals={this.props.proposals}
              focusedTab={this.props.focusedTab}
              // focusedMatchID={this.props.focusedMatchID}
              // focusedEntry={this.props.focusedEntry}
              loginComplete={this.props.loginComplete}
              loginInProgress={this.props.loginInProgress}
              sendTestETH={(amountToSend) => this.getUserTestETH(amountToSend)} 
              // 
              // network={this.props.network} // this comes through WAGMI HOC
          />

          { mainViewDisplay }

          <Footer
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          />
        </>
    );
  }
}

MemeWarsSite.propTypes = {
  // *** functions (get + set) ***
  updateProposals: PropTypes.func.isRequired, // Used in UpNextMatch.jsx TODO
  fetchSessionState: PropTypes.func.isRequired,
  fetchAccount: PropTypes.func.isRequired, 
  // TODO: fetchLobby?
  changeFocusedTab: PropTypes.func.isRequired,
  updateXPBalance: PropTypes.func.isRequired,
  updateETHBalance: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  updateLoginInfo: PropTypes.func.isRequired,
  updateCurrMatch: PropTypes.func.isRequired,
  updateMatches: PropTypes.func.isRequired,
  toggleDemoMode: PropTypes.func.isRequired,
  //
  changeFocusedMatchID: PropTypes.func.isRequired,
  // *** profile state ***
  profile: PropTypes.object,
  account: PropTypes.string,
  provider: PropTypes.string,
  joined: PropTypes.bool,
  XPBalance: PropTypes.object,    // ethers.js BigNumber object 
  ETHBalance: PropTypes.object,   // ethers.js BigNumber object 
  availableETH: PropTypes.object,
  // *** lobby state ***
  lobbyInfo: PropTypes.object,
  lobby: PropTypes.string,
  paid: PropTypes.bool,
  currMatch: PropTypes.object,
  matches: PropTypes.array.isRequired,
  // proposals: state.lobbyInfo.proposals,
  // *** session state ***
  sessionState: PropTypes.object,
  focusedTab: PropTypes.number,       // TODO: change to `focusedTabIndex`
  loginComplete: PropTypes.bool,
  loginInProgress: PropTypes.bool,
  focusedMatchID: PropTypes.number,   // TODO: string?
  web2DemoMode: PropTypes.bool,
  //focusedEntry: PropTypes.bool,     // TODO: string (URL)?
};

// TODO: export profile (state.profile) / lobbyInfo / sessionState etc.
// TODO: make sure .isRequired is being used intelligently
const mapStateToProps = state => ({
  // profile state
  profile: state.profile,
  account: state.profile.account,
  provider: state.profile.provider, 
  network: state.profile.network, // TODO: this should be moved to 'session state'
  joined: state.profile.joined,
  XPBalance: state.profile.XPBalance,
  ETHBalance: state.profile.ETHBalance,
  availableETH: state.profile.availableETH,
  // lobby state
  lobbyInfo: state.lobbyInfo,
  lobby: state.lobbyInfo.lobby,
  paid: state.lobbyInfo.paid,
  currMatch: state.lobbyInfo.currMatch,
  matches: state.lobbyInfo.matches,
  proposals: state.lobbyInfo.proposals,
  // session state
  sessionState: state.sessionState,
  focusedTab: state.sessionState.focusedTab,
  focusedMatchID: state.sessionState.focusedMatchID,
  focusedEntry: state.sessionState.focusedEntry,
  loginComplete: state.sessionState.loginComplete,
  loginInProgress: state.sessionState.loginInProgress,
  web2DemoMode: state.sessionState.web2DemoMode,
});

const MWSiteWithWagmiHooks = WagmiHooksHOC(MemeWarsSite)

export default connect(mapStateToProps, {
    fetchAccount, 
    fetchSessionState, 
    changeFocusedTab, 
    toggleLoginModal, 
    updateLoginInfo,
    updateCurrMatch,
    updateMatches,
    updateXPBalance,
    updateETHBalance,
    updateProposals,
    changeFocusedMatchID,
    toggleDemoMode
                          })(MWSiteWithWagmiHooks);



// POSSIBLE PROPS FOR OTHER COMPONENTS:
// 
// updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
// changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
// updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
// toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
// updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
// updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
// updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
// // ************************* 
// profile={this.props.profile}
// lobbyInfo={this.props.lobbyInfo}
// sessionState={this.props.sessionState}
// // ************************* 
// account={this.props.account} 
// provider={this.props.provider}
// joined={this.props.joined}
// XPBalance={this.props.XPBalance} 
// ETHbalance={this.props.ETHBalance} 
// availableETH={this.props.availableETH}
// lobby={this.props.lobby}
// paid={this.props.paid} 
// currMatch={this.props.currMatch}
// matches={this.props.matches}
// proposals={this.props.proposals}
// focusedTab={this.props.focusedTab}
// focusedMatchID={this.props.focusedMatchID}
// focusedEntry={this.props.focusedEntry}
// loginComplete={this.props.loginComplete}
// loginInProgress={this.props.loginInProgress}