import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSessionState } from '../../actions/sessionStateActions.js';

// CSS and images
import "assets/css/m_w.css";
import styles from "./Modals.module.scss";
import modalPicture0 from "../../assets/img/explainer_rabbit_button_full_green.png";
// import modalPicture1 from "../../assets/img/explainer_rabbit_follow_me.png";
import modalPicture1 from "../../assets/img/betaTabRobot.png";
import modalPicture2 from "../../assets/img/jump.png";
import modalPicture3 from "../../assets/img/seedsmanSlim.jpg";
// import modalPicture4 from "../../assets/img/seedsmanTransparent.png";
import modalPicture4 from "../../assets/img/frontliner.png";
import modalPicture5 from "../../assets/img/explainer_rabbit_follow_me.png";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Modal, Input} from "reactstrap";

// Components
import GreetingModal from './GreetingModal.jsx'; // TODO
import MetricsCollapse from '../MainContent/MetricsCollapse3.jsx'; // TODO: below
// import MetricsCollapse from '../MainContent/MetricsCollapse2.jsx';
// import MetricsCollapse from '../MemeMatch/OverMemeCardCollapse.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa library
import { faWindowClose, faCheck, faCheckSquare, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import { createTrue } from "typescript";
// Loaded via <script> tag in index.html currently - see FORM_EMBED_IMPORT for relevant code 
// import { ReactTypeformEmbed } from 'react-typeform-embed'; 

const slideData = [
  {
    textAlign: 'center',
    bulletPoints: [
      { bold: '', text: '' },
      { bold: '', text: '' },
    ],
  },
  {
    textAlign: 'left', // image on left (bad naming TODO)
    bulletPoints: [
      { bold: 'A tool', text: 'for large-scale AI discourse and coordination' },
      { bold: 'A place', text: 'for social voting games and experiments' },
      { bold: 'An interactive database', text: "of AI ideas (you can improve)" }, // add: and debates
      // { bold: 'An interface', text: 'for shared risks and goals' },
      // { bold: 'A template:', text: 'For a digital community dashboard on processes involving consensus, novelty-surfacing, value-attributing, revenue-generating, etc.' },
      // { bold: 'A re:', text: 'For voting experiments and gamified social AI debates' },
      // { bold: 'An experiment:', text: 'For AI Safety researchers, AI developers, and the general public to collaborate on AI Safety' },
      // through new formats, for identifying top 1% AI predictors, and for coordinating a just whole-society response to AI
    ],
  },
  {
    textAlign: 'right', // image on right (bad naming TODO)
    bulletPoints: [
      { bold: 'Finding top 1%', text: 'of AI policies and predictions' },
      // { bold: 'Create interactive and personalized AI formats', text: 'and time-efficient explainers' },
      // { bold: 'Demonstrating Ethereum (+ZK)', text: ' as AI alignment tech' },
      { bold: 'Creating open-source templates', text: 'for digital deliberation processes' },
      { bold: 'Advancing Public Goods', text: 'ideation and funding ' }, // add: (via novel ad formats)

      // { bold: 'Establishing a shared database of AI debates and opinions', text: 'to be used by a future policy-planner system' },
      // { bold: 'An argument:', text: 'For Ethereum, ZK math, and Quadratic Mechanisms as building blocks of "Social Infrastructure"' },
      // { bold: 'An invitation:', text: 'To iterate on UX which helps improve co-ordination, combat information overwhelm, and fund public goods' },
      // { bold: 'A template:', text: 'for open-source deliberation processes within and between digital and physical communities' },
      // { bold: 'A place:', text: 'to consolidate resources (for a finetune), build a database of perspectives, and to get involved with AI Safety' },
    ],
  },
  {
    textAlign: 'center',
    bulletPoints: [
      { bold: 'The Public', text: 'access and shape AI policy discourse' },
      { bold: 'Researchers', text: 'navigate and contribute concepts' },
      // { bold: 'Digital Groups', text: 'earn money and surface novelty' }, // add: "create and capture value" // add: "difference"
      { bold: 'Digital Groups', text: 'earn money for data labor' }, // add: "create and capture value" // add: "difference"

    ],
  },
  {
    // textAlign: 'Because...',
    textAlign: 'left',
    bulletPoints: [
      { bold: 'Information Overwhelm', text: 'multiplies other shared risks' },
      // { bold: ' Data might be useful', text: "in the future" },
      { bold: 'Data will be useful', text: "in ways we can('t) foresee" },
      { bold: 'Everyone', text: 'should be able to participate in AI decisions' },
      // { bold: 'Public goods', text: 'are good' }, // should be about funding and providing
    ],
  },
  {
    // Searching For
    textAlign: 'left',
    bulletPoints: [
      // { bold: 'Improvements', text: 'to this idea and implementation' },
      { bold: 'Ideas', text: 'for better coordination tools' },
      { bold: 'Developers', text: 'to help with features (ZK, p2p, LLM...)' },
      { bold: 'Collaborators', text: 'for early trials' },
    ],
  },
  // 
];



class SiteLoadOptions extends Component {
  state = {
    optOutAndEmailInModal: false, // TODO
    explainerModalOpen: false,
    sidebarNotClosed: false, // There's a reason for this bad naming, I swear... TODO: replaced state variable names explainerModalOpen != sidebarOpen (more like: sidebarPresent)
    userOptsOutMetrics: true,
    metricsDetailsSelected: false,
    emailInput: "",
    submittedEmail: false,
  }
  
  componentDidMount() {
    this.setState({ sidebarNotClosed: true });
  };

  componentWillUnmount() {
    // window.web3.currentProvider.disconnect();
  };

  componentDidUpdate() {
    if (this.state.sidebarNotClosed != this.props.sidebarOpen) {
      this.setState({ sidebarNotClosed: this.props.sidebarOpen });
    }
  };

  toggleSidebar = () => {
    this.setState({ sidebarNotClosed: !this.state.sidebarNotClosed });
  };

  optOutChanged = () => {
    console.log("metrics opt-out state changed to: " + !this.state.userOptsOutMetrics) // TODO: mixpanel
    this.setState({ userOptsOutMetrics: !this.state.userOptsOutMetrics });
    // this.setState({ userOptsOutMetrics: true });

    // Opts user out of tracking for site-use-metrics // TODO: mixpanel
    if (!this.state.userOptsOutMetrics) {
      // window.mixpanel.track("OPTED_OUT_METRICS", {"PROVIDED_EMAIL": this.state.emailInput}); 
      // window.mixpanel.opt_out_tracking({clear_persistence: false, delete_user: false});
    }

    else {
      // window.mixpanel.track("OPTED_IN_METRICS"); 

       // {track: false} means that we don't want a mixpanel
       // event to fire / get picked up every time someone toggles
       // the "opt-in" button (we have our own event firing already)
      // window.mixpanel.opt_in_tracking({track: false});
    }
  };

  emailInputChange = (e) => {
    // console.log(e.target.value);
    const latestEmail = e.target.value;
    this.setState({ emailInput: latestEmail });
  };

  closeBetaSidebar = () => {
    // console.log("close rules modal");
    // console.log("provided email address:")
    // console.log(this.state.emailInput)

    this.setState({ sidebarNotClosed: false });

    // if user enters email, make state change so icon color can change etc. TODO
    if (this.state.this.state.emailInput != "") {
      this.setState({ submittedEmail: false });
    }

    // if user enters email, send it in mixpanel
    // console.log("EXIT_GREETING_MODAL");
    // TODO: mixpanel
    // window.mixpanel.track("EXIT_GREETING_MODAL", { "OPTED_OUT": this.state.userOptsOutMetrics }); 
    // window.mixpanel.track("EXIT_GREETING_MODAL", {"PROVIDED_EMAIL": this.state.emailInput}); 

  };

  toggleExplainerModal = (arrowIndex) => {
    // TODO: return appropriate photo based on arrowIndex, 0 = modalPicture0, etc.

    // console.log("TOGGLED EXPLAINER MODAL- Open: " + !this.state.explainerModalOpen)
    // this.setState({ explainerModalOpen: !this.state.explainerModalOpen });
  };

  // TODO belwo two mixpanel and more
  openExplainerModal = () => {
    this.setState({ explainerModalOpen: true });
  }

  closeExplainerModal = () => {
    // console.log("EXPLAINER MODAL CLOSED")
    this.setState({ explainerModalOpen: false })
  };

  // if someone clicks question mark next to metrics tracking option (TODO: mixpanel)
  // they will see the details of what data is being tracked / used
  toggleMetricsDetails = () => {
    console.log("METRICS TOGGLED:" + this.state.metricsDetailsSelected)
    this.setState({ metricsDetailsSelected: !this.state.metricsDetailsSelected });
  }

  getExplainerText = () => {
    const arrowIndex = this.props.arrowIndex;
    const currentSlide = slideData[arrowIndex];
  
    // Create an empty array to hold JSX elements
    let bulletPointElements = [];
    let allEmpty = true;
  
    // Loop through bulletPoints and create JSX elements
    if (currentSlide?.bulletPoints) {
      for (let i = 0; i < currentSlide.bulletPoints.length; i++) {
        const point = currentSlide.bulletPoints[i];
        
        if (point.bold !== '' || point.text !== '') {
          allEmpty = false;
        }
        
        const displayStyle = point.bold === '' && point.text === '' ? 'none' : 'list-item';
  
        const element = (
          <li key={i} style={{ display: displayStyle }}>
            <h4 id={styles.betaExplainerBulletText}>
              <strong>{point.bold}</strong> {point.text}
            </h4>
          </li>
        );
        bulletPointElements.push(element);
      }
    }
  
    const listDisplayStyle = allEmpty ? 'none' : 'flex';
  
    // Log the resulting JSX to the console for inspection
    console.log("Bullet Point JSX Elements:", bulletPointElements);
  
    // Return JSX
    return (
      <>
        <div id={styles.betaExaplainerList} style={{ display: listDisplayStyle }}>
          <ul id={styles.betaExplainerBulletpoint}>
            { bulletPointElements }
          </ul>
        </div>
      </>
    );
  };
  
  
  render() {

    // if exit button is hit, sidebar disappears
    const sidebarExited = !this.state.sidebarNotClosed;

    const sidebarVisibleClassname = sidebarExited ? styles.betaSidebarDisappeared : styles.betaTabSideBar;

    // console.log("OPTED OUT: " + this.state.userOptsOutMetrics)

    // Explanation Modal (only seen on first site-load);

    // const modalPicture = require("../../assets/img/explainer_rabbit.png");

    const closeModalIcon = this.state.emailInput == "" ? faWindowClose : faCheckSquare;
    // const closeModalIcon = this.state.emailInput == "" ? <></> : faCheckSquare; // TODO
    // const closeModalIcon = this.state.emailInput == "" ? faWindowClose : faCheckSquare;

    // const closeMetricsDetailsIcon = faCheck;
    const closeMetricsDetailsIcon = faWindowClose;
    // TODO: Icons should be changed in one consistent place
    const questionMarkMetricsIcon = 
    <button id={styles.metricDetailsButton} onClick={this.toggleMetricsDetails}>
      <FontAwesomeIcon icon={faQuestionCircle} id={styles.metricsInfoIcon} />
    </button>

    const metricsDetailsID = this.state.metricsDetailsSelected ? styles.visibleMetricsDetails : styles.invisibleMetricsDetails;
    const emailFormVisibleID = this.state.metricsDetailsSelected ? styles.invisibleGetUpdates : styles.getUpdates;
    const emailSignupIconVisibleID = this.state.emailInput == "" ? styles.invisibleSubmitEmailButton : styles.closeModalButton;

    // style here because it overlaps with another style (#siteExplainer) in .scss module
    const explainerButtonStyle = {
      // Commented-out styles
      // height: "600px",
      // transform: "perspective(401px) rotateY(-12deg) scale(1.1)",
      // transform: "scale(1.75)",
      // transform: "scale(1.25)",
      marginTop: "0px",
      // mixBlendMode: "inherit",
      // mixBlendMode: "difference",
      // opacity: "0",
      // height: "155px",
      // marginTop: "50px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      padding: "0px",
      // mixBlendMode: "lighten"
    };

  // Check if the currentSlide exists (i.e., this.props.arrowIndex is within the bounds of the slideData array)

  let siteExplainerButton;

    switch (this.props.arrowIndex) {
      case 0:
        siteExplainerButton = (
          <button id={styles.siteExplainer} className={styles.siteExplainerButton} style={explainerButtonStyle}
            // onClick={() => this.toggleExplainerModal(0)}
            onClick={this.props.clickRightArrow}>
            <img src={modalPicture0} alt='pic' id={styles.rabbitImage}/>
          </button>
        );
        break;
      case 1:
        siteExplainerButton = (
          <button id={styles.siteExplainerMultiply} className={styles.siteExplainerButton} style={explainerButtonStyle} onClick={() => this.toggleExplainerModal(1)}>
            <img src={modalPicture1} alt='pic' id={styles.betaViewerRobot}/>
          </button>
        );
        break;
      case 2:
        siteExplainerButton = (
          <button id={styles.siteExplainer} className={styles.siteExplainerButton} style={explainerButtonStyle} onClick={() => this.toggleExplainerModal(2)}>
            <img src={modalPicture2} alt='pic' id={styles.betaViewerShip}/>
          </button>
        );
        break;
      case 3:
        siteExplainerButton = (
          <button id={styles.siteExplainer} className={styles.siteExplainerButton} style={explainerButtonStyle} onClick={() => this.toggleExplainerModal(3)}>
            <img src={modalPicture3} alt='pic' id={styles.betaViewerSeedsman}/>
          </button>
        );
        break;
      case 4:
        siteExplainerButton = (
          <button id={styles.siteExplainerMultiply} className={styles.siteExplainerButton} style={explainerButtonStyle} onClick={() => this.toggleExplainerModal(4)}>
            {/* <img src={modalPicture4} alt='pic' id={styles.rabbitImage}/> */}
            <img src={modalPicture4} alt='pic' id={styles.frontlinerImage}/>

          </button>
        );
        break;
      case 5:
        siteExplainerButton = (
          <button id={styles.siteExplainer} className={styles.siteExplainerButton} style={explainerButtonStyle} onClick={() => this.toggleExplainerModal(5)}>
            <img src={modalPicture5} alt='pic' id={styles.rabbitImage}/>
          </button>
        );
        break;
      default:
        siteExplainerButton = null;
        break;
    }

    const arrowIndex = this.props.arrowIndex;
    const currentSlide = slideData[arrowIndex];
    console.log("console.log(currentSlide.bulletPoints)");
    console.log(currentSlide.bulletPoints);

    const siteExplainerText = this.getExplainerText();
    console.log("console.log(siteExplainerText)");
    console.log(siteExplainerText);

    // const explainerStyle = slideData[arrowIndex].textAlign == "right" ?  styles.explainerAndUpdatesImgRight : styles.explainerAndUpdates;
    const explainerStyle = slideData[arrowIndex].textAlign == "right" ?  styles.explainerAndUpdates : styles.explainerAndUpdates;

    
      // const siteExplainerText =
      //   <div className={styles.siteExplainerText}>
      //     HELLO
      //   </div>
    
    const metricsDetailExplainer = 
    <Card id={metricsDetailsID}>
      <div id={styles.metricsDetailsTitle}>
        <div id={styles.emailFormLabel}>
            {/* Data only collected for UX */}
          {/* Tracking only used to improve UX */}
          {/* Web3 = control over your data. Site uses: */}
          Web3 = control over your data
          {/* Data collected only to improve UX */}

          <div id={styles.metricsDetailsSubtitle}>
             {/* Web3 = control your data */}
             {/* Specific tracking: */}
             {/* METRICS: */}
          </div>
        </div>
        </div>


    <div id={styles.metricsDetailsPoints}>
      {/* <div id={styles.metricsCollectedText}>
                Metrics:
      </div> */}

      <div id={styles.emailSubjects}>

      <div id={styles.metricsCollectedText}>
                Metrics:
      </div>

        {/* BETA SITE TRACKS: */}

        <div className={styles.emailSubject}>
        — UX Interacts
        </div> 

        <div className={styles.emailSubject}>
        — Screen Size
        </div>

        <div className={styles.emailSubject}>
        — Region
        </div>

      </div>

      <div id={styles.metricsDetailsButtons}>

        
        <button id={styles.closeModalButton} aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.toggleMetricsDetails}>
                {/* <FontAwesomeIcon id={styles.closeModalIcon} icon={closeModalIcon} onClick={() => this.props.closeSidebarFunction()}/> */}
                <FontAwesomeIcon icon={closeMetricsDetailsIcon} id={styles.closeModalIcon}  />
                {/* // TODO: Handle email  */}
        </button>

      </div>

    </div>

    </Card>

    const explainModal = 
    <>
    <GreetingModal visible={this.state.explainerModalOpen} optOutAndEmailBottom={this.state.optOutAndEmailInModal} closeExplainerFunction={this.closeExplainerModal}/> 
        <div id={styles.betaInfoEmbed}> 

        {/* OPT-OUT TOP START (UNCOMMENT) TODO */}
          {/* OPT-OUT TOP END (UNCOMMENT) TODO */}

          {/* <CardFooter id={styles.rulesModalFooter}>  */}
          <CardFooter id={explainerStyle}> 
                        {/* <ReactTypeformEmbed url="https://memewars.typeform.com/to/Jh9CwYtN" /> */}




      <div id={sidebarVisibleClassname}>
     


          { metricsDetailExplainer }

                    {/* TODO TEST COLLAPSE END HERE */}

        {/* <div id={styles.getUpdates}> */}
        <div id={emailFormVisibleID}>
        
          {/* <Label id={styles.emailFormLabel}> Get updates — (no spam) </Label> */}
          <Label id={styles.emailFormLabel}> Get updates 
          <a href="https://twitter.com/your-twitter-handle" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} id={styles.twitterUpdatesLogo} />
          </a>
            {/* TODO: No Spam <div id={styles.noSpam}> (no spam) </div>  */}
          </Label>
            <div id={styles.emailSubjects}>
                {/* <div className={styles.emailSubject}>
                — Whitepaper
                </div> */}

                <div className={styles.emailSubject}>
                {/* — Own a % of site */}
                — Incentives
                </div>

                <div className={styles.emailSubject}>
                — Trials
                </div>

                <div className={styles.emailSubject}>
                — Launches 
                </div>
             </div>
          {/* <Label id={styles.emailFormLabel}> Get email — beta / how to own a % / launch </Label> */}
          
          {/* <FormGroup id={styles.enterEmail}> */}

          {/* <Input type="email" name="email" id={styles.emailInput} placeholder="beta / how to own a % / launch" /> */}
          {/* <Input type="email" name="emailInput" onChange={this.emailInputChange} id={styles.emailInput} placeholder="you@email.com" /> */}

          {/* <Input type="email" name="emailInput" onChange={this.emailInputChange} id={styles.emailInput} placeholder="email / phone" /> */}

          {/* <form netlify style={{ display: "flex", flexDirection: "row", justifyContent: "center" }} > */}
  {/* <input 
    type="email" 
    name="emailInput" 
    id={styles.enterEmail}
    placeholder="email" 
    onchange="emailInputChange(event)"
    style={{ width: "80%", color: "black"  }}
  /> */}
  {/* <input 
    type="submit" 
    value="✔️" 
    onclick="this.form.submit()" 
    style={{display: "inline", fontSize: "20px }}"}}
  /> */}
{/* </form> */}

          {/* </FormGroup> */}


   
          {/* <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" />{' '}
              Option two can be something else and selecting it will deselect option one
            </Label>
          </FormGroup> */}
          </div>

          <button id={emailSignupIconVisibleID} aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.closeBetaSidebar}>
              <FontAwesomeIcon icon={closeModalIcon} id={styles.closeModalIcon}  onClick={() => this.props.closeSidebarFunction()}/>
              {/* // TODO: Handle email  */}
          </button>

          </div>

            {siteExplainerButton}

            {siteExplainerText}

          </CardFooter>
        </div>
        </>
      

    return explainModal
  }
}

SiteLoadOptions.propTypes = {
  fetchSessionState: PropTypes.func.isRequired,
  account: PropTypes.string,
  provider: PropTypes.string,
  joined: PropTypes.bool,
  lobby: PropTypes.string,
  paid: PropTypes.bool,
  currMatch: PropTypes.object,
  matches: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  account: state.profile.account,
  provider: state.profile.provider, 
  joined: state.profile.joined,
  lobby: state.lobbyInfo.lobby,
  paid: state.lobbyInfo.paid,
  currMatch: state.lobbyInfo.currMatch,
  matches: state.lobbyInfo.matches,
});


export default connect(mapStateToProps, { fetchSessionState })(SiteLoadOptions);



// componentDidMount

    // // starts timer that ends when user exits greeting/rules/beta modal(TODO: mixpanel)
    // window.mixpanel.time_event('EXIT_GREETING_MODAL');
    // // starts timer that ends when user clicks question mark to get more details on metrics (TODO: mixpanel) 
    // window.mixpanel.time_event('METRICS_DETAILS_CLICKED');
    // // starts timer that ends when user clicks LOGIN Button (TODO: mixpanel)
    // window.mixpanel.time_event('LOGIN_BUTTON_CLICKED');
    // // starts timer that ends when user navigates to new tab (TODO: mixpanel)
    // window.mixpanel.time_event('CHANGE_TAB');
    // // starts timer that ends when user clicks a footer link (TODO: mixpanel)
    // window.mixpanel.time_event('CLICKED_FOOTER_LINK');
    // // starts timer that ends when user hovers over top-right badge in "Latest Match" tab (TODO: mixpanel)
    // window.mixpanel.time_event('FORMAT_HOVER_START');
    // // starts timer that ends when user clicks an entry (left or right contestant in meme match) link (TODO: mixpanel)
    // window.mixpanel.time_event('ENTRY_CLICKED');
    // // starts timer that ends when user clicks an entry's details (left or right contestant in meme match) link (TODO: mixpanel)
    // window.mixpanel.time_event('ENTRY_DETAILS_CLICKED');
    // // starts timer that ends when user clicks "Submit Meme + Bid" (left or right PENDING memecard) (TODO: mixpanel)
    // window.mixpanel.time_event('SUBMIT_MEME_OPTIONS_TOGGLED');
    // // starts timer that ends when user submits an entry to a match (left or right PENDING memecard) (TODO: mixpanel)
    // window.mixpanel.time_event('SUBMIT_MEME_COMPLETED');
    // // starts timer that ends when user votes for an entry in an ACTIVE match (left or right PENDING memecard) (TODO: mixpanel)
    // window.mixpanel.time_event('ENTRY_VOTED_FOR');
    // // starts timer that ends when user submits a proposal for a future match (TODO: mixpanel)
    // window.mixpanel.time_event('ADD_PROPOSAL');
    // // starts timer that ends when user upvotes/downvotes an existing proposal  (TODO: mixpanel)
    // window.mixpanel.time_event('VOTE_ON_PROPOSAL');
    // // starts timer that ends when user starts a match (TODO: mixpanel)
    // window.mixpanel.time_event('START_MATCH');


    // explainModal

       {/* <div id={styles.optOutMetricsSidebar}>
        <FormGroup check id={styles.optOutMetrics}>
            <Label check id={styles.optOutLabel}>
              <Input 
                type="radio" 
                name="radio1" 
                // defaultChecked={!this.state.userOptsOutMetrics} 
                checked={!this.state.userOptsOutMetrics} 
                id={styles.optOutSelect}
                // onClick={this.optOutChanged} 
                onClick={this.optOutChanged}
                onChange={this.optOutChanged} 
              /> {' '}


            </Label>
          </FormGroup>




          </div> */}

              {/* <div id={styles.optOutMainText}> Opt out of site-use metrics gathering</div> <div id={styles.optOutDetailText}> (info not personal { "&" } only collected to improve site. <div id={styles.optOutWeb3Text}> WEB3 means control over your own data </div> ) </div>
                */}

  {/* <div id={styles.optOutMainText}> Opt out of metrics (regarding: UI use)  */}
  {/* <div id={styles.optOutMainText}> Help make UX better (tracks UI use) */}
  {/* <div id={styles.optOutMainText}> Help improve UX (tracks UI use) */}
  {/* <div id={styles.optOutMainText}> Opt-in metrics   {questionMarkMetricsIcon} */}
  {/* <div id={styles.optOutMainText}> UX tracking   */}
    {/* <div id={styles.optOutMainText}> UX metrics  {questionMarkMetricsIcon} */}
  {/* <div id={styles.optOutDetailText}> (tracks UI use) </div>  */}

  {/* TODO COLLAPSE START HERE */}

  {/* <div id={styles.optOutMainText}> Send metrics  </div>  */}
  {/* <MetricsCollapse /> */}

  {/* { questionMarkMetricsIcon } */}







  {/* TODO COLLAPSE END HERE */}




         {/*  TODO TEST COLLAPSE START HERE */}

          {/* <MetricsCollapse /> */}