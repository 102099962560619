import React, { Component } from 'react';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse
} from 'reactstrap';
import Select from 'react-select';

// Importing styles
import styles from './SurveyTool.module.scss';
import "../../assets/css/m_w.css";
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock, faPlus, faCaretDown, faCheck, faTimes, faArrowLeft, faArrowRight, faQuestionCircle, faSpinner, faSearch, faExpand, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
// Images
// import skyline from '../../assets/img/skyline.jpg';

// Components
import AudioInput from './AudioInput.jsx';
import surveyData from '../../variables/exampleSurveyData';
// import CreateGroup from '../SBTs/CreateSBTGroup.jsx';
// import { isPropertyAccessOrQualifiedName } from 'typescript';

// Crypto Functionality (encrypt/decrypt) and variables
import { ARWEAVE_ACTIVE  } from '../../variables/CONTRACT_ADDRESSES';
import contractScripts from '../Buttons/contractScripts.js';
import { encrypt } from '@metamask/eth-sig-util';
import { ethers, utils } from 'ethers';
import sha256 from 'crypto-js/sha256';
import Slider from 'react-rangeslider'; 
import { text } from '@fortawesome/fontawesome-svg-core';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';

class SurveyTool extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id={styles.surveySelectorRow}>
        <SurveySelector 
          surveyID={this.props.surveyID}
          displayAnswerMode={this.props.displayAnswerMode}
          viewAddress={this.props.viewAddress}
          //
          account={this.props.account} 
          provider={this.props.provider}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          loginComplete={this.props.loginComplete}
          />

          {/* <CreateSurvey /> */}

          <div id={styles.createSurveyOption}>
            {/* Create Survey */}
            {/* <CreateSurvey /> */}
          </div>

          {/* <div id={styles.whyEncryptButtn}>
            Why Encrypt Answers?
          </div> */}
      </div>
    );
  }
}

class SurveySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      selectedSurveyIndex: null,
      pubKey: '',
      createSurveyMode: false,
      createGroupMode: false,
      demoMode: false,
      showResults: false,
      loading: true,
    };
  }

  async componentDidMount() {
    if (!this.state.demoMode) {
      try {
        const userSubmittedSurveyIDs = await contractScripts.fetchUserSubmittedSurveyIDs(this.props.provider);
        const userSubmittedSurveys = [];
        let selectedSurveyIndex = null;
    
        const surveySet = new Set();
        for (let i = 0; i < userSubmittedSurveyIDs.length; i++) {
          const surveyID = userSubmittedSurveyIDs[i];
          const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
          surveyData.id = surveyID;
    
          if (!surveySet.has(surveyID)) {
            surveySet.add(surveyID);
            userSubmittedSurveys.push(surveyData);
          }
    
          if (surveyID === this.props.surveyID) {
            selectedSurveyIndex = userSubmittedSurveys.length - 1;
          }
        }
    
        this.setState({ surveys: userSubmittedSurveys, selectedSurveyIndex: selectedSurveyIndex, loading: false });
      } catch (error) {
        console.error("Error fetching surveys:", error);
        this.setState({ loading: false });
      }
    } else {
      this.setState({ surveys: surveyData, loading: false });
    }
  }

  selectSurvey = (index) => {
    this.setState({ selectedSurveyIndex: index });
  };

  updatePubKey = (newPubkey) => {
    this.setState({ pubKey: newPubkey });
  };

  toggleCreateMode = () => {
    this.setState({ createSurveyMode: !this.state.createSurveyMode });
  };

  toggleCreateGroupMode = () => {
    this.setState({ createGroupMode: !this.state.createGroupMode });
  };

  toggleShowResults = () => {
    this.setState({ showResults: !this.state.showResults });
  };

  render() {
    const { surveys, selectedSurveyIndex, createSurveyMode, createGroupMode, showResults, loading } = this.state;
    const dropdownTitle = selectedSurveyIndex !== null ? surveys[selectedSurveyIndex].title : 'Select survey';

    const createSurveyComponent = (
      <CreateSurvey
        account={this.props.account}
        loginComplete={this.props.loginComplete}
        provider={this.props.provider}
        toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
        expanded={createSurveyMode}
      />
    );

    // const createGroupComponent = (
    //   <CreateGroup
    //     account={this.props.account}
    //     loginComplete={this.props.loginComplete}
    //     provider={this.props.provider}
    //     toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
    //     expanded={createGroupMode}
    //   />
    // );

    return (
      <div>
        <div id={styles.surveysRow}>
          <UncontrolledDropdown>
            <DropdownToggle id={styles.dropdownToggle}>
              {loading ? (
                <>
                <FontAwesomeIcon icon={faSpinner} spin /> {"Loading Surveys"}
                </>
              ) : (
                <>
                  {dropdownTitle}
                  <FontAwesomeIcon icon={faCaretDown} id={styles.dropdownToggleCaret} />
                </>
              )}
            </DropdownToggle>
            <DropdownMenu id={styles.dropdownMenu}>
              {surveys.map((survey, index) => (
                <DropdownItem className={styles.dropdownItem} key={index} onClick={() => this.selectSurvey(index)}>
                  {survey.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          {selectedSurveyIndex !== null && (
            <Button onClick={this.toggleShowResults} id={styles.showResultsButton}>
              See Results
            </Button>
          )}

          <button id={styles.createSurveyButton} onClick={this.toggleCreateMode}>
            {createSurveyMode ? "Exit Survey Creation" : "Add Question / Create Survey"}
          </button>
        </div>

        {createSurveyMode ? createSurveyComponent : null}
        {/* {createGroupMode ? createGroupComponent : null} */}

        {selectedSurveyIndex !== null ? (
          <SurveyQuestions
            displayAnswerMode={this.props.displayAnswerMode}
            viewAddress={this.props.viewAddress}
            account={this.props.account}
            provider={this.props.provider}
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            surveys={surveys}
            surveyIndex={selectedSurveyIndex}
            loginComplete={this.props.loginComplete}
            pubKey={this.state.pubKey}
            updatePubKey={(newPubkey) => this.updatePubKey(newPubkey)}
          />
        ) : null}

        {this.state.showResults && (
          <SurveyResults
            provider={this.props.provider}
            surveyId={surveys[selectedSurveyIndex].id}
            onClose={this.toggleShowResults}
          />
        )}
      </div>
    );
  }
}

class SurveyQuestions extends Component {
  constructor(props) {
    super(props);

    let initialsurveysResponseState = this.props.surveys.map(survey => {
      let initialAnswers = {};
      let initialAdditionalThoughts = {};

      survey.questions.forEach((question, index) => {
        switch (question.type) {
          case 'multichoice':
            initialAnswers[index] = { value: [], encrypted: false, hash: '' };
            break;
          case 'rating':
            initialAnswers[index] = { value: '', encrypted: false, hash: '' };
            break;
          case 'binary':
            initialAnswers[index] = { value: 'Pass', encrypted: false, hash: '' };
            break;
          default:
            initialAnswers[index] = { value: '', encrypted: false, hash: '' };
            break;
        }
        initialAdditionalThoughts[index] = { value: '', encrypted: false, hash: '' };
      });

      return {
        answers: initialAnswers,
        importance: {},
        additionalComments: initialAdditionalThoughts,
        // HERE: make sure these fields are properly accessed / updated in encryptMultiple...
        encryptedResponsePortion: ''
      };
    });

    this.state = {
      surveysResponseState: initialsurveysResponseState,
      displayAnswerMode: this.props.displayAnswerMode,
      viewAddressAnswers: '',
    };
  }

  async componentDidMount() {
    if (this.props.displayAnswerMode && this.props.viewAddress != undefined) {
      const viewAnswers = await this.getSurveyResponse(this.props.viewAddress, this.props.surveys[this.props.surveyIndex].id);
      console.log("viewAnswers: ");
      console.log(viewAnswers);
      // update state
      this.setState({ viewAddressAnswers: viewAnswers });
    }
  }

  handleAnswer = (surveyIndex, questionIndex, answer) => {
    // console.log("handleAnswer() - answer:" + answer)
    // console.log("surveyIndex: " + surveyIndex)
    // console.log("questionIndex: " + questionIndex)
    const newSurveysResponseState = [...this.state.surveysResponseState];
    let answerStr;
  
    // If the answer is an array or a number, stringify it using JSON.stringify
    if (Array.isArray(answer) || typeof answer === "number") {
      answerStr = JSON.stringify(answer);
    } else {
      answerStr = answer;
    }
  
    newSurveysResponseState[surveyIndex].answers[questionIndex].value = answer;

    const isBinaryQuestion = this.props.surveys[surveyIndex].questions[questionIndex].type == 'binary';
    // Create a hash of the answer value (if it is not list or number or binary question)
    if (!(Array.isArray(answer)) && typeof answer !== "number" && !isBinaryQuestion) {
      const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(answerStr));
      newSurveysResponseState[surveyIndex].answers[questionIndex].hash = newAnswerHash;
    }

    this.setState({ surveysResponseState: newSurveysResponseState });
  };

  toggleDisplayAnswerMode = () => {
    this.setState({ displayAnswerMode: !this.state.displayAnswerMode });
  };

  handleAdditional = (surveyIndex, questionIndex, additionalComments) => {
    const newsurveysResponseState = [...this.state.surveysResponseState];
    newsurveysResponseState[surveyIndex].additionalComments[questionIndex].value = additionalComments;

    // add hash of additional comments
    const newAnswer = newsurveysResponseState[surveyIndex].additionalComments[questionIndex].value;
    const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(newAnswer));
    newsurveysResponseState[surveyIndex].additionalComments[questionIndex].hash = newAnswerHash;
    this.setState({ surveysResponseState: newsurveysResponseState });
  };

  handleImportance = (surveyIndex, questionIndex, importance) => {
    const newsurveysResponseState = [...this.state.surveysResponseState];
    newsurveysResponseState[surveyIndex].importance[questionIndex] = importance;
    this.setState({ surveysResponseState: newsurveysResponseState });
  };

  toggleAnswerEncryption = (surveyIndex, questionIndex) => {
    const newsurveysResponseState = [...this.state.surveysResponseState];
    newsurveysResponseState[surveyIndex].answers[questionIndex].encrypted = !newsurveysResponseState[surveyIndex].answers[questionIndex].encrypted;
    this.setState({ surveysResponseState: newsurveysResponseState });
  };

  toggleAdditionalCommentsEncryption = (surveyIndex, questionIndex) => {
    const newsurveysResponseState = [...this.state.surveysResponseState];
    newsurveysResponseState[surveyIndex].additionalComments[questionIndex].encrypted = !newsurveysResponseState[surveyIndex].additionalComments[questionIndex].encrypted;
    this.setState({ surveysResponseState: newsurveysResponseState });
  };

  getSurveyResponse = async (responderAddress, surveyID) => {
    console.log("getSurveyResponse() - invoked")

    const surveyAnswers = await contractScripts.getSurveyResponse(this.props.provider, responderAddress, surveyID);

    console.log("surveyAnswers: ");
    console.log(surveyAnswers);

    return surveyAnswers;
  };


  prepareJsonAndHash = (surveyIndex) => {

    const surveyHash = this.props.surveys[surveyIndex].id;
    const surveyResponseState = this.state.surveysResponseState[surveyIndex];
    // response to survey ^
    const surveyQuestions = this.props.surveys[surveyIndex].questions;
    // survey questions (TODO: duplication with survey files? makes them more portable)

    console.log("surveyResponseState: ");
    console.log(surveyResponseState);

    const response = {
      surveyID: surveyHash,
      address: this.props.account,
      encryptedPortion: surveyResponseState.encryptedResponsePortion,
      timeStamp: Date.now(),
      responses: Object.keys(surveyResponseState.answers).map((key) => {
        return {
          question: surveyQuestions[key].prompt,
          questionType: surveyQuestions[key].type,
          importance: surveyResponseState.importance[key],
          answer: surveyResponseState.answers[key],
          additional: surveyResponseState.additionalComments[key],
        };
      }),
    };

    return JSON.stringify(response);
  };

  processJsonToTree = (json, level = 0) => {
    let output = [];
  
    if (Array.isArray(json)) {
      json.forEach((item, index) => {
        if (typeof item === 'object') {
          output.push({ type: 'arrayItem', key: index, level });
          output = [...output, ...this.processJsonToTree(item, level + 1)];
        } else {
          output.push({ type: 'arrayItemValue', key: index, value: item, level });
        }
      });
    } else if (typeof json === 'object') {
      Object.keys(json).forEach((key) => {
        if (typeof json[key] === 'object') {
          output.push({ type: 'objectKey', key, level });
          output = [...output, ...this.processJsonToTree(json[key], level + 1)];
        } else {
          output.push({ type: 'objectKeyValue', key, value: json[key], level });
        }
      });
    }
    return output;
  };
  
  jsonTreeDisplay = (jsonString) => {
    const jsonObject = JSON.parse(jsonString);
    const treeData = this.processJsonToTree(jsonObject);
  
    return (
      <ul className={styles.tree}>
        {treeData.map((node, index) => (
          <li
            key={index}
            className={styles.treeItem}
            style={{ marginLeft: `${node.level * 20}px` }}
          >
            <span className={styles.keyValueContainer}>
              {node.type === 'arrayItemValue' && (
                <span>[{node.key}]: {String(node.value)}</span>
              )}
              {node.type === 'objectKeyValue' && (
                <span>{node.key}: {String(node.value)}</span>
              )}
              {node.type === 'arrayItem' && <span>[{node.key}]</span>}
              {node.type === 'objectKey' && <span>{node.key}:</span>}
              {node.type === 'value' && <span>{String(node.value)}</span>}
            </span>
          </li>
        ))}
      </ul>
    );
  };
  
// TODO: move below into contractScripts.js
// ENCRYPT / DECRYPT START ----------------------------------------------------------------------------------------


  getPublicKey = async () => {

    var provider; 
    const address = this.props.account;

    if (this.props.provider == "Torus") {
      provider = window.torusProvider
    }
    else { // NOTE: Specify Metamask
      provider = window.ethereum;
    }

    // const signer = provider.getSigner();

    const pubkey = await provider.request({
      "method": "eth_getEncryptionPublicKey",
      "params": [
        address
      ]
    });

    console.log("pubkey: " + pubkey)
    return pubkey;
  };

  encryptDataForPublicKey = async (publicKey, data) => { // 

        // console.log("publicKey: " + publicKey)

        // Convert hex public key to Uint8Array
        // const publicKeyBytes = ethers.utils.arrayify(publicKey);
        // console.log("publicKeyBytes: " + publicKeyBytes)

        // const utf8pubkey = publicKey.toString('utf8');
        // const altUtf8 = toUtf8(publicKey);
        // console.log("utf8pubkey: " + utf8pubkey)
        // console.log("altUtf8: " + altUtf8)

        // hardcoded argument: https://github.com/MetaMask/eth-sig-util/blob/67a95bab3f414ed8a5718fcb4beccc6088c0db0e/src/encryption.ts#L40C10-L40C36
        const encrypted = await encrypt({publicKey: publicKey, data: data, version: 'x25519-xsalsa20-poly1305'});

        // console.log("encrypted: ")
        // console.log(encrypted)

        // formatting is particular for the eth_decrypt functionality: 
        // https://github.com/ethers-io/ethers.js/issues/4139#issuecomment-1615971987
        // https://metamask.github.io/test-dapp/#getEncryptionKeyButton --> https://github.com/MetaMask/test-dapp/blob/261be4fe4b9f7b774064c090faa617d7b756c3d5/src/index.js#L1320
        const encryptedFormatted = `0x${Buffer.from(JSON.stringify(encrypted), 'utf8').toString('hex')}`
        // equivalent: ethers.utils.hexlify(Buffer.from(JSON.stringify(encrypted)));
        // console.log("encryptedFormatted: ");
        // console.log(encryptedFormatted);

        return encryptedFormatted;
  };

  decryptData = async (encryptedData) => {

    // Note: Works for Metamask / Wagmi, would need change if integrating other providers
    const provider = this.props.provider == "Torus" ? window.torusProvider : window.ethereum;

    const hexCypher = encryptedData;

    try {
      const unencryptedData = await provider.request({
      "method": "eth_decrypt",
      "params": [
        hexCypher, 
        this.props.account, // address (whose public key data is encrypted for)
      ]
    });

    // console.log("unencrypted: " + unencrypted)
    return unencryptedData;
  }

    catch (error) {
      console.log("Error - Data Decryption: ")
      console.log(error);
    }
  };

  encryptMultipleAnswers = async (pubKey) => {
    let textToBeEncrypted = '';
    
    // Directly reference the survey that the user is interacting with
    var currentSurvey = this.state.surveysResponseState[this.props.surveyIndex];

    for (let qIndex in currentSurvey.answers) {
        const answer = currentSurvey.answers[qIndex];
        
        if (answer.encrypted && answer.value != "*") {
            const serializedValue = JSON.stringify(answer.value); // Use JSON.stringify here
            textToBeEncrypted += `%${qIndex}q${serializedValue}`;
            answer.value = '*';
        }
        
        if (currentSurvey.additionalComments && currentSurvey.additionalComments[qIndex]) {
            const additional = currentSurvey.additionalComments[qIndex];
            if (additional.encrypted && additional.value != "*") {
                const serializedValue = JSON.stringify(additional.value); // Use JSON.stringify here
                textToBeEncrypted += `%${qIndex}a${serializedValue}`;
                additional.value = '*';
            }
        }
    }

    // console.log("textToBeEncrypted:");
    // console.log(textToBeEncrypted);

    // Call encryptDataForPublicKey on the textToBeEncrypted
    const encryptedResponsePortion = await this.encryptDataForPublicKey(pubKey, textToBeEncrypted);

    // Add encrypted data to encryptedResponsePortion of the currentSurvey
    currentSurvey.encryptedResponsePortion = encryptedResponsePortion;

    console.log("currentSurvey.encryptedResponsePortion: ")
    console.log(currentSurvey.encryptedResponsePortion)

    // Update the surveysResponseState in the component's state
    const newsurveysResponseState = [...this.state.surveysResponseState];
    newsurveysResponseState[this.props.surveyIndex] = currentSurvey;

    this.setState({ surveysResponseState: newsurveysResponseState });
  };

  decryptMultipleAnswers = async () => {
    // Fetching the encrypted portion of the data from the current survey index
    const encryptedTextHex = this.state.surveysResponseState[this.props.surveyIndex].encryptedResponsePortion;

    // Decrypting the data using your provided decryptData function
    const decryptedText = await this.decryptData(encryptedTextHex);
    const regex = /%(\d+)([qa])([^%]+)/g;
    let match;

    // Clone the surveysResponseState to avoid mutating state directly
    const updatedsurveysResponseState = [...this.state.surveysResponseState];

    // Access the survey the user is currently interacting with
    const currentSurvey = updatedsurveysResponseState[this.props.surveyIndex];

    // Reset encryptedResponsePortion to an empty string
    currentSurvey.encryptedResponsePortion = '';

    // Apply decrypted values and replace '*'
    while ((match = regex.exec(decryptedText)) !== null) {
      const index = match[1];
      const type = match[2];
      let value = match[3];

      // Check if the value is a string representation of an array
      if (value.startsWith('[') && value.endsWith(']')) {
          try {
              value = JSON.parse(value);
          } catch (error) {
              console.error('Error parsing array:', error);
          }
      }

      if (type === 'q') {
          currentSurvey.answers[index].value = value;
      } else if (type === 'a') {
          currentSurvey.additionalComments[index].value = value;
      }
  }

    // Update the cloned surveysResponseState array with the modified currentSurvey
    updatedsurveysResponseState[this.props.surveyIndex] = currentSurvey;

    console.log("unencrypted updatedsurveysResponseState: ")
    console.log(updatedsurveysResponseState)

    // Update the state with the new updatedsurveysResponseState
    this.setState({ surveysResponseState: updatedsurveysResponseState });
  };

  encryptData = async () => {
    var jsonObject;

    // console.log("submitSurvey() - invoked")
    // console.log("this.props.loginComplete: " + this.props.loginComplete)

    if (this.props.loginComplete == false) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }

    else {

      var pubKey = this.props.pubKey;

      if (this.props.pubKey == '') {
        pubKey = await this.getPublicKey();
        this.props.updatePubKey(pubKey)
      }

      // const pubkey = this.props.pubKey == '' ? await this.getPublicKey() : this.props.pubKey
      // const pubKey = await this.getPublicKey(); // Working for Torus and Metamask
      // console.log("pubKey: " + pubKey)

      // const currentSurveyData = this.state.surveysResponseState[this.props.surveyIndex];

      // encrypt relevant sections of current survey data
      this.encryptMultipleAnswers(pubKey);

      // const ciphertext = await this.encryptDataForPublicKey(pubKey, "Cool");
      // console.log("ciphertext: " + ciphertext)

      // const address = this.props.account;
      // console.log("address: " + address)

      // const unencrypted = await this.decryptData(ciphertext);
      // console.log("unencrypted: " + unencrypted);
    }

    const jsonData = this.prepareJsonAndHash(this.props.surveyIndex);
    jsonObject = JSON.parse(jsonData);

    console.log("encrypted jsonObject:")
    console.log(jsonObject)

    // update the internal state of the responses to reflect the encrypted data
  };

// ENCRYPT / DECRYPT END ----------------------------------------------------------------------------------------
    

// TODO: move below into contractScripts.js
// ARWEAVE START ----------------------------------------------------------------------------------------

encryptAndUpload = async () => {
  // Ensure user is logged in first
  if (!this.props.loginComplete) {
    const open = true;
    this.props.toggleLoginModal(open); // Open the login module if user is not logged in.
    return;
  }

  this.encryptData();
  this.submitSurveyResponse();
};

submitSurveyResponse = async () => {
  // Ensure user is logged in first
  if (!this.props.loginComplete) {
    const open = true;
    this.props.toggleLoginModal(open); // Open the login module if user is not logged in.
    return;
  }

  const surveyAnswersData = this.prepareJsonAndHash(this.props.surveyIndex);

  // TODO: above function shouldn't return string
  const surveyAnswersDataJson = JSON.parse(surveyAnswersData);
  // console.log("surveyAnswersData: ")
  // console.log(surveyAnswersData)
  // const surveyResponseID = "0x" + JSON.parse(surveyAnswersData).surveyID.toString();
  // console.log("surveyID: " + surveyResponseID)

  var arweaveHash = '';

  if (ARWEAVE_ACTIVE) {
    // upload the data to arweave with function from contractScripts (WORKING)
    // turn surveyAnswerData into .json format

    // const jsonSurveyAnswersString = JSON.stringify(surveyAnswersDataJson);
    const jsonSurveyAnswersString = JSON.stringify(surveyAnswersData);
    const arweaveResponse = await contractScripts.uploadDataToArweave(jsonSurveyAnswersString, 'json');
    // const arweaveResponse = await contractScripts.uploadDataToArweave(surveyAnswersDataJson, 'json');
    // arweaveHash = arweaveResponse.transaction.id;
    arweaveHash = arweaveResponse;
    console.log("arweaveHash: " + arweaveHash)

  }
  else {
     arweaveHash = "Qqxt9hceiiTSqAlFzYTC7Bd1HXrBh99V0S-0a4Kok-s"; // TODO: remove - test Data
  }

  // Convert arweaveHash (base64URL) to bytes (hexadecimal format)
  const arweaveHashBytes = contractScripts.base64urlToHex(arweaveHash);
  const arweaveHashBytesString = arweaveHashBytes.toString();
  console.log("arweaveHashBytesString: " + arweaveHashBytesString)

  // Submit the survey response (Arweave hash in bytes format) to Smart Contract storage
  // with the survey ID as an input

  const surveyID = this.props.surveys[this.props.surveyIndex].id
  console.log("surveyID: " + surveyID)

  const response = await contractScripts.submitSurveyResponse(this.props.provider, surveyID, arweaveHashBytesString);
  console.log("response: ")
  console.log(response)
  if (response.status == 1) {
    console.log("Survey response submitted successfully!")
  }

  else {
    console.log("Error submitting survey response.")
  }
};

// ARWEAVE END ------------------------------------------------------------------------------------------


  renderQuestion = (question, qIndex, currentSurveyResponseState) => {
    let questionComponent;
  
    switch (question.type) {
      case 'multichoice':
        questionComponent = (
          <FormGroup id={styles.multiChoice}>
            {question.options.map((option, oIndex) => (
              <Label check key={oIndex} className={styles.checkboxOptionText}>
                <Input
                  type="checkbox"
                  name={`question-${qIndex}`}
                  value={option}
                  onChange={(e) => {
                    const newAnswer = [...currentSurveyResponseState.answers[qIndex].value];
                    if (e.target.checked) {
                      newAnswer.push(option);
                    } else {
                      const index = newAnswer.indexOf(option);
                      if (index > -1) {
                        newAnswer.splice(index, 1);
                      }
                    }
                    this.handleAnswer(this.props.surveyIndex, qIndex, newAnswer);
                  }}
                />
                {option}
              </Label>
            ))}
          </FormGroup>
        );
        break;
      case 'rating':
        questionComponent = (
          <>
            {/* <Input
              type="range"
              min={0}
              max={10}
              value={this.state.surveysResponseState[this.props.surveyIndex].answers[qIndex].value}
              onChange={(e) => this.handleAnswer(this.props.surveyIndex, qIndex, e.target.value)}
              id={styles.ratingInput}
            /> */}
               <div className={styles.importanceSlider}>
                <Slider
                    min={0}
                    max={10}
                    // min={question.min}
                    // max={question.max}
                    step={1}
                    // value={this.state.surveysResponseState[this.props.surveyIndex].answers[qIndex].value}
                    value={currentSurveyResponseState.answers[qIndex].value || 0}
                    tooltip={false}
                    onChange={(ratingAnswer) => this.handleAnswer(this.props.surveyIndex, qIndex, ratingAnswer)}
                    id={styles.ratingSlider}
                    style={{ width: '200px'}}
                    // id={styles.ratingSlider}
                  /> 
                  </div>

            <FormText id={styles.ratingLabelText}>
              {/* Rating:  */} {"  "}
              {currentSurveyResponseState.answers[qIndex].value}</FormText>
          </>
        );
        break;

      case 'binary':
        questionComponent = (
          <FormGroup id={styles.binaryChoice}>
            {['Agree', 'Pass', 'Disagree'].map((option, oIndex) => (
              <Label check key={oIndex} className={`${styles.radioOptionText} ${styles[option.toLowerCase()]} ${currentSurveyResponseState.answers[qIndex].value === option ? styles.selected : ''}`}>
                <Input
                  type="radio"
                  name={`question-${qIndex}`}
                  value={option}
                  checked={currentSurveyResponseState.answers[qIndex].value === option}
                  onChange={() => this.handleAnswer(this.props.surveyIndex, qIndex, option)}
                />
                {option === 'Agree' && <FontAwesomeIcon icon={faCheck} className={styles.optionIcon} />}
                {option === 'Disagree' && <FontAwesomeIcon icon={faTimes} className={styles.optionIcon} />}
                {option}
              </Label>
            ))}
          </FormGroup>
        );
        break;

        default: // 'freeform'
        questionComponent = (
          <AudioInput 
            qIndex={qIndex} 
            placeholder={'response (optional)'}
            updateFunction={(answer) => this.handleAnswer(this.props.surveyIndex, qIndex, answer)} 
            toggleEncryption={() => this.toggleAnswerEncryption(this.props.surveyIndex, qIndex)}
          />
        );
        break;
    }
  
    return (
      <Card key={qIndex}>
        <CardBody id={styles.questionTitleBody}>
          <h4 id={styles.questionTitle}>
            {question.prompt}
          </h4>
          <InputGroup>
            {questionComponent}
            {question.type !== 'freeform' && (
              <InputGroupAddon addonType="append" id={styles.encryptAdditionalThoughts}>
                <div id={styles.encryptOptionButton} onClick={() => this.toggleAnswerEncryption(this.props.surveyIndex, qIndex)}>
                  <FontAwesomeIcon id={styles.encryptIcon} icon={currentSurveyResponseState.answers[qIndex].encrypted ? faLock : faUnlock} />
                  <InputGroupText id={styles.inputGroupTextBox}>
                    <Input
                      addon
                      type="checkbox"
                      aria-label="encrypt"
                      checked={currentSurveyResponseState.answers[qIndex].encrypted}
                      // onChange={() => this.toggleAnswerEncryption(this.props.surveyIndex, qIndex)}
                      // onClick={() => this.toggleAnswerEncryption(this.props.surveyIndex, qIndex)}
                      onCheck={() => this.toggleAnswerEncryption(this.props.surveyIndex, qIndex)}
                      id={styles.encryptCheckbox}
                    />
                    Encrypt
                  </InputGroupText>
                </div>
              </InputGroupAddon>
            )}
          </InputGroup>
          <div className={styles.importanceSlider}>
            <h6 id={styles.importanceText}>Importance: {currentSurveyResponseState.importance[qIndex]} </h6>
            <Slider
              min={0}
              max={10}
              step={1}
              value={currentSurveyResponseState.importance[qIndex] || 0}
              tooltip={false}
              onChange={(importance) => this.handleImportance(this.props.surveyIndex, qIndex, importance)}
            />
          </div>
          <AudioInput 
            qIndex={qIndex} 
            updateFunction={(additionalComments) => this.handleAdditional(this.props.surveyIndex, qIndex, additionalComments)} 
            toggleEncryption={() => this.toggleAdditionalCommentsEncryption(this.props.surveyIndex, qIndex)}
            placeholder={'related thoughts or URLs (optional)'}
          />
          
          {/* <Button 
            className={styles.encryptButton} 
            onClick={() => this.toggleAdditionalCommentsEncryption(this.props.surveyIndex, qIndex)}
          >
            <FontAwesomeIcon icon={currentSurveyResponseState.additionalComments[qIndex].encrypted ? faLock : faUnlock} />
            Encrypt Additional
          </Button> */}
        </CardBody>
      </Card>
    );    
  };
  
  render() {
    const { surveys } = this.props;
    const currentSurvey = surveys[this.props.surveyIndex];
    const currentSurveyResponseState = this.state.surveysResponseState[this.props.surveyIndex];
    const viewingAnswers = this.props.viewAnswerMode


    const jsonRepresentation = this.state.displayAnswerMode && this.state.viewAddressAnswers != '' ?
    this.state.viewAddressAnswers : this.prepareJsonAndHash(this.props.surveyIndex);

    const jsonDisplay = this.jsonTreeDisplay(jsonRepresentation)
    // console.log("jsonRepresentation:")
    // console.log(jsonRepresentation)

    // const shortenedViewAddress = proposalScripts.getShortenedAddress(this.props.viewAddress);
    // slice the address to display only the first 6 and last 4 characters

    const notClickable = false;

    const shortenedViewAddress = this.props.viewAddress != undefined ? 
    proposalScripts.getShortenedAddress(this.props.viewAddress, notClickable) : '';

    const viewAnswersButton = this.props.viewAddress != undefined ? (
      <Button onClick={this.toggleDisplayAnswerMode} id={styles.answerSurveyButton}>
      <FontAwesomeIcon icon={faArrowLeft} id={styles.encryptIcon} />
      <div id={styles.surveyButtonText}> View {shortenedViewAddress} answers </div>
    </Button>
    ) : null;
      
    return (
      <div>

        {this.state.displayAnswerMode && this.props.viewAddress != undefined ? (
          <Button onClick={this.toggleDisplayAnswerMode} id={styles.answerSurveyButton}>
            <FontAwesomeIcon icon={faPlus} id={styles.encryptIcon} />
            <div id={styles.surveyButtonText}> Fill out survey </div>
          </Button>
        ) : (
          <>

            { viewAnswersButton }

            {currentSurvey.questions.map((question, qIndex) => this.renderQuestion(question, qIndex, currentSurveyResponseState))}

            <div className={styles.footer} id={styles.surveyFooter}>
            {/* <Button id={styles.submitSurveyButton} onClick={this.encryptData}> 
              Encrypt
            </Button> */}

            <Button id={styles.submitSurveyButton} onClick={this.decryptMultipleAnswers}> 
              Decrypt / Edit
            </Button>

            <Button id={styles.submitSurveyButton} onClick={this.encryptAndUpload}> 
              Encrypt / Upload
            </Button>
            </div>
          </>
        )}

        {/* {this.state.displayAnswerMode ? (
          `Viewing answer of: ${this.props.viewAddress}`
        ) : (
          null
        )} */}

        <p id={styles.responsePreview}>
        {/* { jsonRepresentation } */}
          {jsonDisplay}
        </p>
      </div>
    );
  }
}

class CreateSurvey extends Component {
  constructor() {
    super();
    this.state = {
      title: '',
      questions: [],
      addingQuestionType: '',
      surveySubmitted: false,
      surveyHash: '',
      isSubmitting: false,
      progress: 0,
      showJson: false,
    };
  }

  componentDidMount() {
    this.loadFromLocalStorage();
  }

  loadFromLocalStorage = () => {
    const savedSurvey = localStorage.getItem('unfinishedSurvey');
    if (savedSurvey) {
      this.setState(JSON.parse(savedSurvey), this.updateSurveyHash);
    }
  };

  saveToLocalStorage = () => {
    localStorage.setItem('unfinishedSurvey', JSON.stringify(this.state));
  };

  generateQuestionId = (type, prompt) => {
    const hash = sha256(`${type}:${prompt.toLowerCase()}`);
    // return hash.toString().substring(0, 16);
    return "0x" + hash;
  };

  handleQuestionChange = (index, key, value) => {
    const { questions } = this.state;
    questions[index][key] = value;
    if (key === 'prompt') {
      questions[index].id = this.generateQuestionId(questions[index].type, value);
    }
    this.setState({ questions }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  addQuestion = () => {
    const type = this.state.addingQuestionType;
    if (!type) return;

    const newQuestion = {
      type: type,
      prompt: '',
      id: this.generateQuestionId(type, '')
    };

    if (type === "multichoice") {
      newQuestion.options = [];
    }

    this.setState(prevState => ({
      questions: [...prevState.questions, newQuestion],
      addingQuestionType: ''
    }), () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  removeQuestion = (index) => {
    this.setState(prevState => ({
      questions: prevState.questions.filter((_, i) => i !== index)
    }), () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleOptionChange = (questionIndex, optionIndex, value) => {
    const { questions } = this.state;
    questions[questionIndex].options[optionIndex] = value;
    this.setState({ questions }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  addOption = (questionIndex) => {
    const { questions } = this.state;
    questions[questionIndex].options.push('');
    this.setState({ questions }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  removeOption = (questionIndex, optionIndex) => {
    const { questions } = this.state;
    questions[questionIndex].options = questions[questionIndex].options.filter((_, i) => i !== optionIndex);
    this.setState({ questions }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  updateSurveyHash = () => {
    const { title, questions } = this.state;
    const surveyData = { title, questions };
    const newSurveyHash = "0x" + sha256(JSON.stringify(surveyData)).toString();
    this.setState({ surveyHash: newSurveyHash });
  };

  createSurvey = async () => {
    console.log("createSurvey function called");

    if (!this.props.loginComplete) {
      console.log("Login not complete. Please log in to create a survey");
      this.props.toggleLoginModal(true);
      return;
    }

    console.log("Login complete. Proceeding with survey creation.");
    this.setState({ isSubmitting: true, progress: 0 });

    const { title, questions, surveyHash } = this.state;
    console.log("Survey State Values:", { title, questions, surveyHash });

    const surveyData = { title, questions };
    console.log("Survey Data:", surveyData);

    try {
      const jsonSurveyData = JSON.stringify(surveyData);
      console.log("JSON Survey Data:", jsonSurveyData);

      const progressInterval = setInterval(() => {
        this.setState(prevState => {
          const newProgress = Math.min(prevState.progress + 10, 90);
          console.log("Progress:", newProgress);
          return { progress: newProgress };
        });
      }, 500);

      const receipt = await contractScripts.addSurvey(this.props.provider, surveyHash, jsonSurveyData);
      console.log("Transaction Receipt:", receipt);

      clearInterval(progressInterval);

      if (receipt.status === 1) {
        console.log("Transaction successful");
        this.setState({ surveySubmitted: true, isSubmitting: false, progress: 100 });
        localStorage.removeItem('unfinishedSurvey');
      } else {
        console.error("Transaction failed with status:", receipt.status);
        throw new Error("Transaction failed");
      }
    } catch (error) {
      console.error("Failed to create survey:", error);
      this.setState({ isSubmitting: false, progress: 0 });
    }
};


  copySurveyLinkToClipboard = () => {
    const surveyLink = `${window.location.origin}/survey/${this.state.surveyHash}`;
    navigator.clipboard.writeText(surveyLink)
      .then(() => {
        console.log(`Successfully copied ${surveyLink}`);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  toggleShowJson = () => {
    this.setState(prevState => ({ showJson: !prevState.showJson }));
  };

  render() {
    const { title, questions, surveySubmitted, surveyHash, isSubmitting, progress, showJson } = this.state;

    const surveyData = { title, questions, surveyHash };

    const questionTypeTooltips = {
      freeform: "Allows respondents to enter any text as an answer.",
      rating: "Lets respondents rate on a scale, typically from 0 to 10.",
      multichoice: "Provides multiple options for respondents to choose from.",
      binary: "Presents an agree/disagree or yes/no type question."
    };

    return (
      <div className={styles.createSurveyContainer}>
        <Input
          className={styles.surveyTitleInput}
          placeholder="Survey Title"
          value={title}
          onChange={this.handleTitleChange}
        />

        {questions.map((question, qIndex) => (
          <div key={qIndex} className={styles.questionContainer}>
            <div className={styles.questionHeader}>
              <strong className={styles.questionTypeText}>#{qIndex + 1}: {question.type} question</strong>
              <Button className={styles.removeQuestionButton} onClick={() => this.removeQuestion(qIndex)}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
            <Input
              className={styles.questionPromptInput}
              placeholder="Question prompt"
              value={question.prompt}
              onChange={e => this.handleQuestionChange(qIndex, 'prompt', e.target.value)}
            />
            {question.type === "multichoice" && (
              <div className={styles.optionsContainer}>
                {question.options.map((option, oIndex) => (
                  <div key={oIndex} className={styles.optionItem}>
                    <Input
                      placeholder="Option"
                      value={option}
                      onChange={e => this.handleOptionChange(qIndex, oIndex, e.target.value)}
                      className={styles.optionInput}
                    />
                    <Button className={styles.removeOptionButton} onClick={() => this.removeOption(qIndex, oIndex)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                ))}
                {question.options.length < 10 && (
                  <Button className={styles.addOptionButton} onClick={() => this.addOption(qIndex)}>
                    <FontAwesomeIcon icon={faPlus} /> Add Option
                  </Button>
                )}
              </div>
            )}
            <div className={styles.questionId}>{question.id}</div>
          </div>
        ))}

        <div className={styles.addQuestionContainer}>
          <select
            className={styles.questionTypeSelect}
            value={this.state.addingQuestionType}
            onChange={e => this.setState({ addingQuestionType: e.target.value })}
          >
            <option value="">Question Type</option>
            {Object.entries(questionTypeTooltips).map(([type, tooltip]) => (
              <option key={type} value={type} title={tooltip}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </option>
            ))}
          </select>
          <Button className={styles.addQuestionButton} onClick={this.addQuestion}>
            <FontAwesomeIcon icon={faPlus} /> Add Question
          </Button>
        </div>

        <p className={styles.surveyIdPreview}>New Survey ID: {surveyHash}</p>

        {surveySubmitted ? (
          <div className={styles.surveySubmittedContainer}>
            <Button className={styles.copySurveyLinkButton} onClick={this.copySurveyLinkToClipboard}>
              <FontAwesomeIcon icon={faCheck} className={styles.surveySubmittedIcon} />
              Copy Survey Link
            </Button>
            <a href={`/survey/${surveyHash}`} target="_blank" rel="noopener noreferrer" className={styles.viewSurveyLink}>
              <FontAwesomeIcon icon={faExternalLinkAlt} /> View Survey
            </a>
          </div>
        ) : (
          <Button className={styles.createSurveyButton} onClick={this.createSurvey} disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin /> Creating Survey...
              </>
            ) : (
              "Add Qs / Create Survey"
            )}
          </Button>
        )}

        {isSubmitting && (
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
          </div>
        )}

        <Button className={styles.toggleJsonButton} onClick={this.toggleShowJson}>
          {showJson ? "Hide JSON" : "Show JSON"}
        </Button>

        {showJson && (
          <pre className={styles.jsonDisplay}>
            {JSON.stringify(surveyData, null, 2)}
          </pre>
        )}
      </div>
    );
  }
}

class SurveyResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responses: [],
      csvData: '',
      filter: '',
      exportType: 'CSV',
      activeToggles: {},
      modalOpen: true
    };
  }

  async componentDidMount() {
    const responses = await contractScripts.fetchAllSurveyResponses(this.props.provider, this.props.surveyId);
    console.log("Fetched responses:", responses);
    this.setState({ responses }, this.generateCSV);
  }

  parseResponse(responseData) {
    try {
      return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (error) {
      console.error("Error parsing response data:", error);
      return null;
    }
  }

  generateCSV = () => {
    const { responses } = this.state;
    console.log("Generating CSV for responses:", responses);
    if (!responses.length) {
      console.log("No responses to generate CSV");
      return;
    }

    const header = 'responderAddress,questionID,question,questionType,importance,answer,answerHash,additionalComments,answerEncrypted,additionalEncrypted,additionalHash\n';
    const csvRows = [];

    responses.forEach((response, responseIndex) => {
      console.log(`Processing response ${responseIndex + 1}:`, response);
      const parsedResponse = this.parseResponse(response.response);
      if (parsedResponse && parsedResponse.responses) {
        parsedResponse.responses.forEach((answer, index) => {
          console.log(`Processing answer ${index + 1} for response ${responseIndex + 1}:`, answer);
          const row = [
            response.responder,
            answer.questionID || '',
            answer.question || '',
            answer.questionType || '',
            answer.importance || '',
            answer.answer ? answer.answer.value : '',
            answer.answer ? answer.answer.hash : '',
            answer.additional ? answer.additional.value : '',
            answer.answer ? answer.answer.encrypted : '',
            answer.additional ? answer.additional.encrypted : '',
            answer.additional ? answer.additional.hash : ''
          ].map(value => `"${value !== undefined ? value : ''}"`).join(',');
          csvRows.push(row);
        });
      } else {
        console.log(`Invalid response structure for response ${responseIndex + 1}`);
      }
    });

    const csvData = header + csvRows.join('\n');
    console.log("Generated CSV data:", csvData);
    this.setState({ csvData });
  };

  downloadCSV = () => {
    const { csvData } = this.state;
    console.log("Downloading CSV with data:", csvData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `survey_responses_${this.props.surveyId}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  handleFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  handleExportTypeChange = (type) => {
    this.setState({ exportType: type });
  };

  toggleResponse = (index) => {
    this.setState(prevState => ({
      activeToggles: {
        ...prevState.activeToggles,
        [index]: !prevState.activeToggles[index]
      }
    }));
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  renderResponseContent = (response) => {
    const parsedResponse = this.parseResponse(response.response);
    if (!parsedResponse) {
      return <div>No response data available</div>;
    }

    const { timeStamp, responses } = parsedResponse;
    const formattedTimestamp = new Date(parseInt(timeStamp)).toLocaleString();

    return (
      <div>
        <p><strong>Timestamp:</strong> {formattedTimestamp}</p>
        {responses && responses.map((item, index) => (
          <div key={index} className={styles.responseItem}>
            <h4 className={styles.questionTitle}>{item.question}</h4>
            <p><strong>Question Type:</strong> {item.questionType}</p>
            <p><strong>Importance:</strong> {item.importance}</p>
            <p><strong>Answer:</strong> {item.answer.value}</p>
            <p><strong>Answer Encrypted:</strong> {item.answer.encrypted.toString()}</p>
            {/* <p><strong>Answer Hash:</strong> {item.answer.hash}</p> */}
            {item.additional && item.additional.value && (
              <>
                <p><strong>Additional Comments:</strong> {item.additional.value}</p>
                <p><strong>Additional Encrypted:</strong> {item.additional.encrypted.toString()}</p>
                {/* <p><strong>Additional Hash:</strong> {item.additional.hash}</p> */}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { responses, filter, exportType, activeToggles, modalOpen } = this.state;
    const filteredResponses = responses.filter(response => 
      response.responder.toLowerCase().includes(filter.toLowerCase())
    );

    return (
      <Modal isOpen={modalOpen} toggle={this.closeModal} className={styles.resultsModal}>
        <ModalHeader toggle={this.closeModal} className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>Survey Results</h2>
          <Button className={styles.closeButton} onClick={this.closeModal}>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className={styles.filterContainer}>
            <Input
              type="text"
              placeholder="Filter by address"
              value={filter}
              onChange={this.handleFilterChange}
              className={styles.filterInput}
            />
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
          </div>

          <div className={styles.responseList}>
            {filteredResponses.map((response, index) => (
              <div key={index} className={styles.responseItem}>
                <div 
                  className={styles.responseHeader}
                  onClick={() => this.toggleResponse(index)}
                >
                  <span className={styles.responderAddress}>
                    <a 
                      href={`/u/${response.responder}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      className={styles.responderLink}
                    >
                      {proposalScripts.getShortenedAddress(response.responder)}
                    </a>
                    <a 
                      href={`/survey/${this.props.surveyId}/${response.responder}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      className={styles.externalLink}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                    {response.response && response.response.encryptedPortion && (
                      <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
                    )}
                  </span>
                  <FontAwesomeIcon icon={faCaretDown} className={styles.caretIcon} />
                </div>
                <Collapse isOpen={!!activeToggles[index]}>
                  <Card>
                    <CardBody className={styles.responseCard}>
                      {this.renderResponseContent(response)}
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            ))}
          </div>

          <div className={styles.exportContainer}>
            <Label for="exportType" className={styles.exportLabel}>EXPORT AS:</Label>
            <div className={styles.exportOptions}>
              <UncontrolledDropdown direction="up">
                <DropdownToggle caret className={styles.exportDropdown}>
                  {exportType}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.handleExportTypeChange('CSV')}>CSV</DropdownItem>
                  <DropdownItem onClick={() => this.handleExportTypeChange('Polis Report')}>Polis Report</DropdownItem>
                  <DropdownItem onClick={() => this.handleExportTypeChange('Talk To The City Report')}>Talk To The City Report</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Button onClick={this.downloadCSV} className={styles.downloadButton}>
                Download
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default SurveyTool;
