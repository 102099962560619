import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faDownload, faQuestionCircle, faCheck, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip, FormGroup, Label, Input } from 'reactstrap';
import contractScripts from '../Buttons/contractScripts.js';
import styles from './DatabaseTool.module.scss';
import generatedSeedStatements from './generated_seed_statements_demo.json';

const DatabaseTool = ({ provider, network }) => {
  const [url, setUrl] = useState('');
  const [ingressType, setIngressType] = useState('text');
  const [questionTypes, setQuestionTypes] = useState({
    binary: true,
    freeform: true,
    rating: true,
    multichoice: true
  });
  const [count, setCount] = useState(5);
  const [loading, setLoading] = useState(false);
  const [seedStatements, setSeedStatements] = useState([]);
  const [error, setError] = useState('');
  const [demoMode, setDemoMode] = useState(false);
  const [selectedStatements, setSelectedStatements] = useState([]);
  const [answers, setAnswers] = useState({});

  const ingressOptions = [
    { value: 'text', label: 'Text' },
    { value: 'video', label: 'Video (Coming Soon)', disabled: true },
    { value: 'audio', label: 'Audio (Coming Soon)', disabled: true },
  ];

  const questionTypeOptions = [
    { value: 'binary', label: 'Binary' },
    { value: 'freeform', label: 'Freeform' },
    { value: 'rating', label: 'Rating' },
    { value: 'multichoice', label: 'Multichoice' },
  ];

  useEffect(() => {
    if (demoMode) {
      setUrl('https://situational-awareness.ai/wp-content/uploads/2024/06/situationalawareness.pdf');
      const filteredStatements = generatedSeedStatements.questions.filter(
        statement => questionTypes[statement.questionType]
      );
      setSeedStatements(filteredStatements);
      initializeAnswerStates(filteredStatements);
    } else {
      setSeedStatements([]);
      setAnswers({});
    }
  }, [demoMode, questionTypes]);

  const initializeAnswerStates = (statements) => {
    const initialAnswers = {};
    statements.forEach((statement, index) => {
      switch (statement.questionType) {
        case 'binary':
          initialAnswers[index] = 'Pass';
          break;
        case 'rating':
          initialAnswers[index] = 5;
          break;
        case 'freeform':
          initialAnswers[index] = '';
          break;
        case 'multichoice':
          initialAnswers[index] = [];
          break;
        default:
          break;
      }
    });
    setAnswers(initialAnswers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSeedStatements([]);

    try {
      // Fetch URL content
      const response = await fetch(url);
      const content = await response.text();

      // Generate seed statements using AI
      const statements = await generateSeedStatements(content, questionTypes, count);

      setSeedStatements(statements);
      initializeAnswerStates(statements);
    } catch (err) {
      setError('Error processing URL: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const generateSeedStatements = async (content, questionTypes, count) => {
    // This is a placeholder for the actual AI invocation
    console.log('Generating seed statements with AI...');
    console.log('Content:', content);
    console.log('Question Types:', questionTypes);
    console.log('Count:', count);
    
    // In a real implementation, this would call the AI service
    // For now, we'll return the filtered demo statements
    const filteredStatements = generatedSeedStatements.questions.filter(
      statement => questionTypes[statement.questionType]
    );
    return filteredStatements.slice(0, count);
  };

  const handleQuestionTypeChange = (type) => {
    setQuestionTypes(prevTypes => ({
      ...prevTypes,
      [type]: !prevTypes[type]
    }));
  };

  const handleStatementSelection = (index) => {
    setSelectedStatements(prevSelected => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter(i => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const addSelectedToDB = async () => {
    // Placeholder function for adding selected statements to the database
    console.log('Adding selected statements to database:', selectedStatements);
    // Here you would call the contract method to add these to the question bank
    // await contractScripts.addToQuestionBank(provider, selectedStatements);
  };

  const handleAnswerChange = (index, value) => {
    setAnswers(prev => ({ ...prev, [index]: value }));
  };

  const handleMultichoiceChange = (index, option) => {
    setAnswers(prev => {
      const currentSelection = prev[index] || [];
      if (currentSelection.includes(option)) {
        return { ...prev, [index]: currentSelection.filter(item => item !== option) };
      } else {
        return { ...prev, [index]: [...currentSelection, option] };
      }
    });
  };

  const renderQuestionDisplay = (statement, index) => {
    return (
      <div key={index} className={styles.questionDisplay}>
        <div className={styles.questionText}>{statement.question}</div>
        <div className={styles.answerDisplay}>
          {statement.questionType === 'binary' && (
            <FormGroup id={styles.binaryChoice}>
              {['Agree', 'Pass', 'Disagree'].map((option) => (
                <Label check key={option} className={`${styles.radioOptionText} ${styles[option.toLowerCase()]} ${answers[index] === option ? styles.selected : ''}`}>
                  <Input
                    type="radio"
                    name={`question-${index}`}
                    value={option}
                    checked={answers[index] === option}
                    onChange={() => handleAnswerChange(index, option)}
                  />
                  {option === 'Agree' && <FontAwesomeIcon icon={faCheck} className={styles.optionIcon} />}
                  {option === 'Disagree' && <FontAwesomeIcon icon={faTimes} className={styles.optionIcon} />}
                  {option}
                </Label>
              ))}
            </FormGroup>
          )}
          {statement.questionType === 'freeform' && (
            <Input
              type="textarea"
              value={answers[index] || ''}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              placeholder="Your answer here..."
              className={styles.freeformAnswer}
            />
          )}
          {statement.questionType === 'rating' && (
            <div className={styles.ratingAnswer}>
              <input 
                type="range" 
                min="0" 
                max="10" 
                value={answers[index] || 5} 
                onChange={(e) => handleAnswerChange(index, parseInt(e.target.value))} 
                className={styles.ratingSlider} 
              />
              <span className={styles.ratingValue}>{answers[index] || 5}/10</span>
            </div>
          )}
          {statement.questionType === 'multichoice' && (
            <div className={styles.multichoiceAnswer}>
              {statement.options.map((option, optionIndex) => (
                <Label check key={optionIndex} className={styles.multichoiceOption}>
                  <Input
                    type="checkbox"
                    checked={(answers[index] || []).includes(option)}
                    onChange={() => handleMultichoiceChange(index, option)}
                  />
                  {option}
                </Label>
              ))}
            </div>
          )}
        </div>
        <input
          type="checkbox"
          checked={selectedStatements.includes(index)}
          onChange={() => handleStatementSelection(index)}
          className={styles.statementCheckbox}
        />
      </div>
    );
  };

  return (
    <div className={styles.databaseTool}>
      <div className={styles.header}>
        <h2>
          AI Database Tool
          <FontAwesomeIcon icon={faQuestionCircle} id="helpTooltip" className={styles.helpIcon} />
          <UncontrolledTooltip target="helpTooltip" placement="right">
            This tool ingests a URL or PDF, processes the content, and generates seed statements or questions for debate. You can also download various data sets related to the surveys and simulated users.
          </UncontrolledTooltip>
        </h2>
        <button 
          className={`${styles.demoModeButton} ${demoMode ? styles.active : ''}`}
          onClick={() => setDemoMode(!demoMode)}
        >
          {demoMode ? 'Disable Demo Mode' : 'Enable Demo Mode'}
        </button>
      </div>
      
      <form onSubmit={handleSubmit}>
        {/* <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Input Source</h3>
          <div className={styles.ingressOptions}>
            {ingressOptions.map((option) => (
              <button
                key={option.value}
                type="button"
                onClick={() => !option.disabled && setIngressType(option.value)}
                className={`${styles.ingressButton} ${ingressType === option.value ? styles.active : ''} ${option.disabled ? styles.disabled : ''}`}
                disabled={option.disabled}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div> */}
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>URL or PDF to Ingest
          <div className={styles.ingressOptions}>
            {ingressOptions.map((option) => (
              <button
                key={option.value}
                type="button"
                onClick={() => !option.disabled && setIngressType(option.value)}
                className={`${styles.ingressButton} ${ingressType === option.value ? styles.active : ''} ${option.disabled ? styles.disabled : ''}`}
                disabled={option.disabled}
              >
                {option.label}
              </button>
            ))}
          </div>
          </h3>
          <div className={styles.urlInputGroup}>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              required
              placeholder="Enter URL or upload PDF"
            />
            <label className={styles.uploadButton}>
              <FontAwesomeIcon icon={faUpload} />
              <input
                type="file"
                accept=".pdf"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setUrl(file.name);
                    // Handle file upload logic here
                  }
                }}
              />
            </label>
          </div>
        </div>
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Question Types to Generate</h3>
          <div className={styles.questionTypeOptions}>
            {questionTypeOptions.map((option) => (
              <label key={option.value} className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={questionTypes[option.value]}
                  onChange={() => handleQuestionTypeChange(option.value)}
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>
        
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Number of Questions</h3>
          <div className={styles.countInputGroup}>
            <input
              type="number"
              value={count}
              onChange={(e) => setCount(parseInt(e.target.value))}
              min="1"
              required
            />
          </div>
        </div>
        
        <button type="submit" className={styles.generateButton} disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Generate Seed Statements'}
        </button>
      </form>

      {error && <div className={styles.error}>{error}</div>}

      {seedStatements.length > 0 && (
        <div className={styles.results}>
          <h3>Generated Seed Statements:</h3>
          <div className={styles.statementsContainer}>
            {seedStatements.map((statement, index) => renderQuestionDisplay(statement, index))}
          </div>
          <button onClick={addSelectedToDB} className={styles.addButton}>
            Upload Selected to Question Bank
          </button>
        </div>
      )}

      <div className={styles.downloadSection}>
        <h3>Download AI Database Data</h3>
        <div className={styles.downloadButtons}>
          <button onClick={() => console.log('Downloading question bank')}>
            <FontAwesomeIcon icon={faDownload} /> Question Bank
          </button>
          <button onClick={() => console.log('Downloading responses')}>
            <FontAwesomeIcon icon={faDownload} /> Responses
          </button>
          <button onClick={() => console.log('Downloading simulated user profiles')}>
            <FontAwesomeIcon icon={faDownload} /> Simulated User Profiles
          </button>
        </div>
      </div>
    </div>
  );
};

export default DatabaseTool;