import React, { Component } from 'react';

// CSS
import styles from "./Navbar.module.scss";

// for Smart-Contract functionality
import contractScripts from '../Buttons/contractScripts.js';

// reactstrap components
import { Container, Col } from 'reactstrap';

class BalanceDisplay extends Component {
  componentDidMount() {}

  componentDidUpdate() {
    // console.log("BalanceDisplay - UPDATED")
    // if (this.props.loginComplete) {
    //   console.log("BalanceDisplay LOGGED IN - XPBalance:")
    //   console.log(this.props.XPBalance.toString())
    // }
  }

  render() {

    // ************** XP 

    // {} is default starting value for this.props.XPBalance (accountReducer.js)
    const XPBalanceAvailable = this.props.XPBalance != {}; 
    const loggedIn = this.props.loginComplete;

    const XPBalance = XPBalanceAvailable && loggedIn ? this.props.XPBalance : contractScripts.getBigNumber(0);

    console.log("XPBalance: ")
    console.log(XPBalance)

    const XPBalanceNumber = this.props.XPBalance == undefined ? 0 : contractScripts.decimalEighteen(XPBalance, "BalanceDisplay");

    console.log("XPBalance: ")
    console.log(XPBalance)
    const XPBalMessage = XPBalance == undefined || XPBalanceNumber == 0 ? "100" : XPBalanceNumber.toString();

    // const XPBalDisplay = <><div id={styles.XPBalanceIcon}> <i className="fas fa-coins"></i></div> <div id={styles.balanceText} > {" Balance: "} </div> <div id={styles.balanceNumber}> {XPBalMessage} </div> </>;
    const XPBalDisplay = (
      <>
        <a
          href="https://www.radicalxchange.org/wiki/quadratic-voting/"
          target="_blank"
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          <div
            id={styles.balanceText}
            style={{
              display: 'inline',
              textDecoration: 'underline',
              textDecorationColor: 'currentColor',
              textDecorationThickness: '2px',
              textUnderlineOffset: '2px',
              textDecorationOpacity: '0.5',
            }}
          >
            {"Votes:"}
          </div>
          <div
            id={styles.balanceNumber}
            style={{
              display: 'inline',
              // textDecoration: 'underline',
              // textDecorationColor: 'currentColor',
              // textDecorationThickness: '2px',
              // textUnderlineOffset: '2px',
              // textDecorationOpacity: '0.5',
            }}
          >
           {"‎ ‎ " + XPBalMessage}
          </div>
          <div
            id={styles.XPBalanceIcon}
            style={{
              display: 'inline',
            }}
          >
            <i className="fas fa-comment-dots"></i>
          </div>
        </a>
      </>
    );
    
    // ************** ETH (TODO)
    const ETHBalance = this.props.ETHBalance
    // const ETHBalMessage =  web3.utils.fromWei(this.props.ETHbalance);
    const ETHBalMessage = ETHBalance == undefined || ETHBalance == 0 ? "0" : contractScripts.decimalEighteen(this.props.availableETH, "BalanceDisplay").substring(0, 5);
    // const ETHBalDisplay = <>< div id={styles.ETHBalanceIcon}> <i className="fab fa-ethereum"></i> </div> {" Balance: "} {ETHBalMessage} </>;
    const ETHBalDisplay = <> {"ETH Balance: "} {ETHBalMessage} </>;

    // const coinIcon = this.props.paid ?  : <i className="fas fa-up-down"></i>;
    // const coinIconDiv = <div id="ETHXPBalanceIcon">{ coinIcon }</div>;

    // ************* GLOWING (TODO)
    
    var correctGlowBalances = this.props.paid ?
    <h2 className={styles.glow}  id={styles.ETHBalanceText}> { ETHBalDisplay } </h2>
    :   
    <h2 className={styles.glow} id={styles.XPBalanceText}> { XPBalDisplay } </h2>;

    return (
      <>
        <div>
            {correctGlowBalances}
        </div>
      </>
    );
  }
}

export default BalanceDisplay;
