import React, { Component } from "react";

// components
import ExplorerTabPluginExpanded from "components/MainContent/ExplorerTabPluginExplainer";
import SurveyTool from "components/SurveyTool/SurveyTool.jsx";

// CSS and styles
import artHeader from '../../assets/img/artHeader.png';
import styles from './SurveyPage.module.scss';

const surveyExplainText = 'AI-assisted survey platform allowing detailed responses and questions. Responses can be encrypted for privacy and retroactive evaluation by ZK systems (opt-in). Similar to pol.is but incorporating advanced question formats and preference weighing. Audio input not stored by this app.';

class SurveyComponent extends Component {
    render() {
        return (
            <div>
                <ExplorerTabPluginExpanded
                    explainText={surveyExplainText}
                    headerImage={artHeader}
                />
                           <div id={styles.bannerImageContainer}> 
              <img src={artHeader} id={styles.bannerImage}/> 
            </div>

                <SurveyTool 
                    //
                    surveyID={this.props.surveyID}
                    displayAnswerMode={this.props.displayAnswerMode}
                    viewAddress={this.props.viewAddress}
                    //
                    toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
                    account={this.props.account} 
                    provider={this.props.provider}
                    joined={this.props.joined}
                    XPBalance={this.props.XPBalance} 
                    ETHbalance={this.props.ETHBalance} 
                    availableETH={this.props.availableETH}
                    lobby={this.props.lobby}
                    paid={this.props.paid} 
                    loginComplete={this.props.loginComplete}
                    loginInProgress={this.props.loginInProgress}
                    network={this.props.network}
                />
            </div>
        );
    }
}

export default SurveyComponent;
