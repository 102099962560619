import React, { useEffect, useState } from 'react';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import styles from './SBTsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExpand, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NavLink, Button } from 'reactstrap';

const SBTsList = ({ provider, modalView, network }) => {
  const [sbtList, setSbtList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cache, setCache] = useState(JSON.parse(localStorage.getItem('sbtCache')) || {});
  const [refreshing, setRefreshing] = useState(false);

  const fetchSBTs = async (forceRefresh = false) => {
    setLoading(true);
    let sbtDetails;
    let latestBlock;

    const isCacheValid = (cache) => {
      return !forceRefresh &&
             cache.networkID === network.id &&
             cache.sbtList &&
             Object.values(cache.sbtList).every(sbt => sbt.mintedAddresses && sbt.burnedAddresses);
    };
    
    if (isCacheValid(cache)) {
      console.log("Using cached data");
      sbtDetails = Object.values(cache.sbtList);
      latestBlock = cache.lastBlock;
    } else {
      try {
        const sbts = await contractScripts.getSbtsCreated(provider);
        sbtDetails = await Promise.all(sbts.map(async (sbt) => {
          const metadata = await contractScripts.getSbtMetadata(provider, sbt.sbtAddress);
          const mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(provider, sbt.sbtAddress);
          const burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(provider, sbt.sbtAddress);
          return { ...sbt, metadata, mintedAddresses, burnedAddresses };
        }));

        latestBlock = await contractScripts.getLatestBlockNumber(provider);

        // Create cache
        const newCache = {
          ...cache,
          [network.id]: {
            lastBlock: latestBlock,
            sbtList: sbtDetails.reduce((acc, sbt) => {
              acc[sbt.sbtAddress] = sbt;
              return acc;
            }, {})
          }
        };
        localStorage.setItem('sbtCache', JSON.stringify(newCache));
        setCache(newCache);
      } catch (error) {
        console.error('Error fetching SBTs:', error);
        setLoading(false);
        return;
      }
    }

    sbtDetails.sort((a, b) => {
      const netMintedA = a.mintedAddresses.length - a.burnedAddresses.length;
      const netMintedB = b.mintedAddresses.length - b.burnedAddresses.length;
      return netMintedB - netMintedA;
    });

    setSbtList(sbtDetails);
    setLoading(false);
  };

  useEffect(() => {
    fetchSBTs();
  }, [provider, cache, network]);

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchSBTs(true);
    setRefreshing(false);
  };

  const handleClearCache = async () => {
    localStorage.removeItem('sbtCache');
    setCache({});
    await fetchSBTs(true);
  };

  const renderSBT = (sbt) => {
    const { sbtAddress, metadata, mintedAddresses, burnedAddresses } = sbt;
    const { name, description, image } = metadata;
    const netMinted = mintedAddresses.length - burnedAddresses.length;

    return (
      <button
        key={sbtAddress}
        className={styles.sbtItem}
        onClick={() => window.location.href = `/sbt/${sbtAddress}`}
      >
        <div className={styles.sbtImage}>
          <img src={image} alt="SBT Thumbnail" width="150" height="150" />
        </div>
        <div className={styles.sbtInfo}>
          <p className={styles.sbtName}>{name}</p>
          <p className={styles.sbtAddress}>
            {proposalScripts.getShortenedAddress(sbtAddress, false)}
          </p>
          <p className={styles.sbtDescription}>{description}</p>
          <p className={styles.sbtNetMinted}>Net Minted: {netMinted}</p>
        </div>
      </button>
    );
  };

  const isMintingLive = (sbt) => {
    const now = Math.floor(Date.now() / 1000);
    return sbt.metadata.mintingEndTime === 0 || sbt.metadata.mintingEndTime > now;
  };

  if (loading) {
    return (
      <div className={styles.spinner}>
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
      </div>
    );
  }

  const mintingLiveSBTs = sbtList.filter(isMintingLive);
  const expiredSBTs = sbtList.filter(sbt => !isMintingLive(sbt));

  return (
    <div className={styles.sbtListContainer}>
      <div className={styles.header}>
        {/* {modalView && (
          <div className={styles.expandIcon}>
            <NavLink href="/sbts">
              <FontAwesomeIcon icon={faExpand} size="lg" />
            </NavLink>
          </div>
        )} */}
        <Button
          className={styles.refreshButton}
          onClick={handleRefresh}
          disabled={refreshing}
        >
          <FontAwesomeIcon icon={faSync} spin={refreshing} /> Refresh
        </Button>
        <Button
          className={styles.clearCacheButton}
          onClick={handleClearCache}
          disabled={refreshing}
        >
          <FontAwesomeIcon icon={faTrash} /> Clear Cache
        </Button>
      </div>
      <h2 className={styles.sectionTitle}>Minting Live</h2>
      {mintingLiveSBTs.map(renderSBT)}
      <h2 className={styles.sectionTitle}>Minting Expired</h2>
      {expiredSBTs.map(renderSBT)}
    </div>
  );
};

export default SBTsList;