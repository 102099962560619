import React, { Component } from "react";

// CSS & Images
// import "./ContributorsArea.module.scss";
import styles from "./ContributorsArea.module.scss";
import nftImage from '../../assets/img/seedsmanTransparent.png';

// Components
import PublicTrello from "./PublicTrello";

class ContributorsArea extends Component {
  state = {
    hasNftAccess: false,
    password: ""
  };

  componentDidMount() {
    this.checkNFTStatus();
  }

  componentDidUpdate(prevState) {
    // if (prevState.password !== "" && prevState.password != this.state.password) {
    //     this.checkNFTStatus();
    // }
  }

  checkNFTStatus = () => {
    // Placeholder logic to set the hasNftAccess state
    // You will need to replace this with your own logic to check for the presence of the correct ERC-721 NFT

    if (this.state.password == "password") {
        return true;
        // this.setState({ hasNftAccess: true });
    }

    else {
        return false;
        // this.setState({ hasNftAccess: false });
    }
  };

  handlePasswordChange = event => {
    console.log("event: " + event);
    this.setState({ password: event.target.value });
    console.log("password: " + event.target.value);    
    this.checkNFTStatus();
  }

  handleSubmit = event => {
    event.preventDefault();
    const hasNFT = this.checkNFTStatus();

    if (hasNFT && !this.state.hasNftAccess) {
        this.setState({ hasNftAccess: true });
    }

    // logic to check password and update hasNftAccess state
    // ...
  }

  render() {
    const { hasNftAccess, password } = this.state;

    return (
    <>
            
      <div className={styles.ContributorsArea}>
        {!hasNftAccess ? (
          <div className={styles.notAbleToView}>
            <h1>NFT required to access page</h1>
            <img src={nftImage} alt="NFT Preview" id={styles.nftImage} />
            <form onSubmit={this.handleSubmit} id={styles.nftPasswordInputForm}>
            <div class={styles.inputContainer}>
                
                <input type="text" 
                       placeholder="enter password"
                       class={styles.inputBar} 
                       value={password}
                       onChange={this.handlePasswordChange}/>
                </div>
                {/* <SlantedInputBar /> */}
              <button type="submit" id={styles.nftPasswordSubmitButton}>Mint</button>
            </form>
          </div>
        ) : (
          <div className={styles.ableToView}>
            <PublicTrello />
            {/* <div className={styles.leftSide}>
              <h1>About</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                placerat, nibh non commodo feugiat, lacus sem blandit lectus,
                id mollis sapien enim at ipsum.
              </p>
              <p>
                <span className={styles.highlighted}>Clickable words</span>
              </p>
            </div>
            <div className={styles.rightSide}>
              <h1>Explorer</h1>
            </div> */}
          </div>
        )}
      </div>

    </>
    );
  }
}

export default ContributorsArea;