import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label } from 'reactstrap';
// import htmlFile from '../../../public/ideasMap.html';
import styles from './IdeaMap.module.scss';

function IdeaMap() {
  const [htmlContent, setHtmlContent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [clickedContent, setClickedContent] = useState(null);
  const [intensity, setIntensity] = useState(5);

  useEffect(() => {
    fetch('/ideasMap.html')
      .then(response => response.text())
      .then(data => setHtmlContent(data));
  }, []);

  const handleCellClick = (content) => {
    console.log('Cell clicked');
    setClickedContent(content.map(c => c.data).join(' ')); // Convert content to string
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setClickedContent(null);
  };

  const handleIntensityChange = (e) => {
    setIntensity(Number(e.target.value));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted');
    console.log('Intensity:', intensity);
    // Perform actions to store in the database here
    handleCloseModal();
  }

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs || !attribs.class) return;
      if (attribs.class.includes("table-cell")) {
        return <td className={styles.tableCell} onClick={() => handleCellClick(children)}>{children}</td>;
      }
    }
  };

  return (
    <div className={styles.myComponent}>
      {htmlContent && parse(htmlContent, options)}
      <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Clicked Content</ModalHeader>
        <ModalBody>
          <p>{clickedContent}</p>
          <form onSubmit={handleFormSubmit}>
            <div>
              <Label for="intensity">Intensity: {intensity}</Label>
              <input
                type="range"
                id="intensity"
                name="intensity"
                min="1"
                max="10"
                value={intensity}
                onChange={handleIntensityChange}
              />
            </div>
            <Input type="textarea" name="arguments" placeholder="Your arguments" /><br/>
            <Input type="textarea" name="citations" placeholder="Your citations" /><br/>
            <Button color="primary" type="submit">Submit</Button>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default IdeaMap;
