import React, { Component } from "react";
// import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeFocusedTab } from '../../actions/sessionStateActions.js';

// CSS
import 'assets/css/m_w.css';
import styles from "./MainContent.module.scss";

// reactstrap components
import { Button, Container } from "reactstrap";

// Components
import SiteLoadOptions from '../InformationModals/SiteLoadOptions.jsx';
import DemoModeSelect from "components/LoginAndWeb3/DemoModeSelect.jsx";

// Loaded via <script> tag in index.html currently - see FORM_EMBED_IMPORT for relevant code 
// import { ReactTypeformEmbed } from 'react-typeform-embed'; 
// import { ReactTypeformEmbed } from window.typeformEmbed; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa library
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

// .env variables
import { TYPEFORM_SURVEY_URL } from '../../variables/CONTRACT_ADDRESSES.js';

class BetaTab extends Component {
    state = {
      openSidebar: false,
      arrowIndex: 0, // 0 is first image (with associated image modal popup)
      numSlides: 6, // TODO: change this around so not hardcoded
    }
  componentDidMount() {

    // console.log(window.typeformEmbed)

    // var typeformObject; // TODO

    // window.addEventListener("DOMContentLoaded", function() {

    // typeformEmbed = window.typeformEmbed.makePopup('typformURL', {
    //     hideHeaders: true,
    //     hideFooter: true
    //   });

      // typeformEmbed = window.typeformEmbed;

      // console.log(typeformEmbed)

      // console.log(wi)
    // });

    // typeformEmbed = typeformObject;
    // console.log(typeformObject)

  }

  componentDidUpdate() {}

  clickRightArrow = () => {
  
    // NOTE: for ES6 import of TypeForm. see FORM_EMBED_IMPORT 
    // this.typeformEmbed.typeform.open();

    // TODO: confirm typeform is loaded
    // typeformEmbed.open();
    // if (typeformEmbed != "not loaded" && typeformEmbed !== undefined && typeformEmbed != null) {
    //   window.typeformEmbed.open();
    // }

    // TODO: else, callback in a second + show loading
    this.setState({ arrowIndex: this.state.arrowIndex + 1 });
  }

  clickLeftArrow = () => {
    this.setState({ arrowIndex: this.state.arrowIndex - 1 });
  }

  clickFinalButton = () => {
    this.props.changeTabFunction(4);
  }

  toggleSidebar = () => {
    // console.log("toggleSidebar")
    this.setState({ openSidebar: !this.state.openSidebar });
  }

  getBetaTabHeadline = () => {
    const titleOptions = {
      0: '‎ ',
      1: "A few things...",
      2: 'With Goal Of...', // for: AI discourse / coordination / planning / prediction
      3: 'Built to help...',
      4: 'Because...', // for: public goods / artists / creators / communities
      5: 'In search of...',
    };
  
    const title = titleOptions[this.state.arrowIndex] || '';
  
    return title ? (
      <div id={styles.betaInfo}>
        <div id={styles.betaTabTitleArea}>
          <h2 id={styles.betaTabTitle}>{title}</h2>
        </div>
      </div>
    ) : null;
  };
  
  getLeftButton = () => {
    if (this.state.arrowIndex > 0) {
      return (
        <button id={styles.takeSurveyButton} onClick={this.clickLeftArrow} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon id={styles.takeSurveyIcon} icon={faArrowLeft} />
        </button>
      );
    } 
    else {
      return (
        <button id={styles.openSidebarButton} 
          // onClick={this.toggleSidebar}
          >
          <DemoModeSelect 
          textMode={true}
          toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
          demoMode={this.props.demoMode}
          />
          {/* Demo Mode */}
        </button>
      );
    }
    // TODO: uncomment for Updates options
    // else {
    //   return (
    //     <button id={styles.openSidebarButton} onClick={this.toggleSidebar}>
    //       Updates
    //     </button>
    //   );
    // }
  }
  
  getRightButton = () => {
    
    // console.log("getRightButton")
    // console.log(this.state.arrowIndex, this.state.numSlides)

    if (this.state.arrowIndex < this.state.numSlides - 1) {
      return (
        <button id={styles.takeSurveyButton} onClick={this.clickRightArrow} style={{ cursor: 'pointer' }}>
          <div id={styles.feedbackPromptText}>
            {/* Rate this idea */}
          </div>
          <FontAwesomeIcon id={styles.takeSurveyIcon} icon={faArrowRight} />
          <div id={styles.feedbackPromptDetailText}>
            {/* Get NFT */}
          </div>
        </button>
      );
    } else {
      return (
        <button onClick={this.clickFinalButton} id={styles.openSidebarButton} className={styles.getStartedButton}>
          Get started
        </button>
      );
    }
  }
     

  render() {

    const betaTabHeadline = this.getBetaTabHeadline();

    const leftButton = this.getLeftButton();
    const rightButton = this.getRightButton();

    return (
      <div className="block-gradient-slow">
        {/* <div className="gradient-border"> */}
        <Container id={styles.betaTab}>

        <div id={styles.betaInfo}>


         { betaTabHeadline }


            <SiteLoadOptions 
              arrowIndex={this.state.arrowIndex} 
              sidebarOpen={this.state.openSidebar} 
              closeSidebarFunction={this.toggleSidebar}
              clickRightArrow={this.clickRightArrow}
              clickLeftArrow={this.clickLeftArrow} />
        </div>



           <div id={styles.betaSurvey}>   {/* //TODO: change name beacuse it also includes sidebar prompt button */}

            <div id={styles.sidebarOpen}>

               {  leftButton  }

            </div>


            {/* <h4 id={styles.takeSurveyPrompt}>Earn a beta-tester NFT (very short): </h4> */}
            {/* <button id={styles.takeSurveyButton} onClick={this.clickRightArrow} style={{ cursor: 'pointer' }} > Rate this idea -> receive NFT </button> */}
            {/* <button id={styles.takeSurveyButton} onClick={this.clickRightArrow} style={{ cursor: 'pointer' }} > Give Feedback */}

              { rightButton }

            </div>

            {/* <Button onClick={() => this.props.changeTabFunction(1)} >Cool</Button> */}

        </Container>
        {/* </div> */}
      </div>
    );
  }
}

export default BetaTab;


