// const surveyData = [
//   {
//   title: "SB-1047",
//   questions: [
//   {
//   type: "binary",
//   prompt: "Should AI developers be required to implement cybersecurity protections and safety protocols to prevent misuse of AI systems they develop?",
//   },
//   {
//   type: "binary",
//   prompt: "Should AI developers have to test their AI models for potential hazardous capabilities before releasing them?",
//   },
//   {
//   type: "binary",
//   prompt: "Should AI developers be required to submit annual certifications to a government agency regarding the safety of their AI models?",
//   },
//   {
//   type: "binary",
//   prompt: "Should California establish a new Frontier Model Division to oversee AI development and issue AI safety guidance?",
//   },
//   {
//   type: "binary",
//   prompt: "Should AI developers be required to provide uniform public pricing and equitable access to their AI models?",
//   },
//   {
//   type: "binary",
//   prompt: "Should employees of AI companies have whistleblower protections to report potential AI safety violations?",
//   },
//   {
//   type: "binary",
//   prompt: "Should the state government invest in building a public cloud computing cluster (CalCompute) to support AI research?",
//   },
//   {
//   type: "freeform",
//   prompt: "What are your biggest concerns about the development of advanced AI systems?"
//   },
//   {
//   type: "freeform",
//   prompt: "Are there any aspects of this proposed legislation you disagree with, and if so, why?"
//   },
//   {
//   type: "freeform",
//   prompt: "What additional measures, if any, do you think should be taken to ensure the safe and responsible development of AI?"
//   }
//   ]
//   }
//   ];
//   export default surveyData;
  
  const surveyData = [
{
  title: "Polis-Style Questions",
  questions: [
    {
      type: "binary",
      prompt: "Should AI labs be nationalized by the government?",
    },
    {
      type: "binary", 
      prompt: "Should there be a single airgapped facility for AI development?",
    },
    {
      type: "binary",
      prompt: "Should elected citizens be present at AI labs to provide oversight?",
    },
    {
      type: "binary",
      prompt: "Should there be a global moratorium on advanced AI development until safety measures are in place?",
    },
    {
      type: "binary",
      prompt: "Should AI systems be required to have a human-in-the-loop for high-stakes decisions?",
    },
    {
      type: "binary",
      prompt: "Should AI companies be required to share their research openly?",
    },
    {
      type: "binary",
      prompt: "Should there be strict testing and certification requirements before AI systems are deployed?",
    },
    {
      type: "binary",
      prompt: "Should AI developers be held legally liable for harms caused by their systems?",
    },
    {
      type: "binary",
      prompt: "Should there be a dedicated government agency to oversee AI development and deployment?",
    },
    {
      type: "binary",
      prompt: "Should the use of AI for surveillance and law enforcement be banned or severely restricted?",
    }
  ]
},
  {
    title: "Baseline AI Survey",
    questions: [
      {
        type: "binary",
        prompt: "Do you believe AI should be regulated by governments?",
      },
      {
        type: "freeform",
        prompt: "What is an aspect of AI (risk or otherwise) other people don’t agree with you on?"
      },
      {
        type: "rating",
        prompt: "How worried are you about the risk of extinction (or disaster) stemming from misuse of AI?",
      },
      {
        type: "multichoice",
        prompt: "How do you want to be protected from AI?",
        options: ["Data Privacy", "Job Security", "Physical Safety", "Not Worried"],
      }
    ]
  },
  {
    title: "Economic Impact",
    questions: [
      {
        type: "binary",
        prompt: "Should we move towards a Universal Basic Income (UBI) as a result of rapid AI progress?",
        options: ["Yes", "No"]
      },
      {
        type: "rating",
        prompt: "How worried are you about the risk of losing employment due to AI?",
      },
      {
        type: "multichoice",
        prompt: "What type of job would you like more if yours is 'lost' to AI?",
        options: ["Creative", "Community Support", "Hands For Robots", "RLHF / Data Review", "None"],
      },
      {
        type: "rating",
        prompt: "To what degree should AI capabilities progress be 'throttled' and 'directed' by anti-poverty and global wellbeing imperatives?",
      }
    ]
  },
  {
    title: "Research and Skills",
    questions: [
      {
        type: "freeform",
        prompt: "What type of research are you interested in doing with AI?"
      },
      {
        type: "multichoice",
        prompt: "What are your personal skills relevant to AI?",
        options: ["Programming", "Data Analysis", "Machine Learning", "Policy Making", "Ethics"],
      }
    ]
  },
  {
    title: "Future Prediction",
    questions: [
      {
        type: "freeform",
        prompt: "In what way can you imagine AI making your life better / worse?"
      },
      {
        type: "rating",
        prompt: "How good do you think you are at anticipating advances in technology, generally?",
      },
      {
        type: "freeform",
        prompt: "What is an AI prediction (of capabilities pace, social outcome, or policy inevitability) you have?"
      }
    ]
  },
  {
    title: "Privacy Questions",
    questions: [
      {
        type: "freeform",
        prompt: "What does privacy mean to you with regard to AI?"
      },
      {
        type: "binary",
        prompt: "Would you trust cryptographic systems more than human ones?",
        options: ["Yes", "No"]
      },
      {
        type: "rating",
        prompt: "How much would you trust 'software by specification' rather than code made by humans?",
      }
    ]
  }
];

export default surveyData;
