import React, { Component } from "react";
import { Container, Row } from 'reactstrap';

// CSS
import "assets/css/m_w.css"; 
import styles from "./RightSide.module.scss";

// Components
import PlayersOnline from "../RightSidebar/PlayersOnline.jsx";
// import AdSpot from "../RightSidebar/AdSpot.jsx";
import ChatBox from "../RightSidebar/ChatBox.jsx";
import NetworkStats from "../RightSidebar/NetworkStats.jsx";
// import Profile from './Profile.jsx'
import AdSpotWithImages from "../RightSidebar/AdSpotWithImages.jsx";

class Rightside extends Component {

  componentDidMount() {}

  componentDidUpdate() {}

  render () {

  return (
    <div id={styles.rightSideContainer}>
      <PlayersOnline 
        className={styles.rightSideCard} 
        numPlayers={this.props.numPlayers} 
        demoMode={this.props.demoMode}
        />
      <ChatBox 
        className={styles.rightSideCard}
        demoMode={this.props.demoMode}
        />
      {/* <AdSpot className={styles.rightSideCard} /> */}
      {/* <NetworkStats className={styles.rightSideCard} /> */}
      {/* <Profile className={styles.rightSideCard} account={this.props.account} /> */}
      <AdSpotWithImages 
      className={styles.rightSideCard} 
      demoMode={this.props.demoMode}
      />
    </div>
  );
  }
};

export default Rightside;